import React from 'react'
import { Typography } from '@material-ui/core';
import '../App.css'
import { Log } from '../utils';
import { fontIcon } from '../res';

const TAG = 'FontIcon'


export function FontIcon(props) {

    // Log.verbose(TAG, JSON.stringify(props));

    const style = { ...props.style, fontFamily: 'fi' }

    var comp;
    switch (props.fontIcon) {

        case fontIcon.icon_search:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe0aa;</Typography>
            break;

        case fontIcon.icon_post_add:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe0d4;</Typography>
            break;
        case fontIcon.icon_buy:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe09a;</Typography>
            break;
        case fontIcon.icon_sell:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe099;</Typography>
            break;
        case fontIcon.icon_get_on_rent:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe0d6;</Typography>
            break;
        case fontIcon.icon_give_on_rent:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe0e2;</Typography>
            break;
        case fontIcon.icon_shop:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe055;</Typography>
            break;
        case fontIcon.icon_down_arrow_2:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe0c7;</Typography>
            break;
        case fontIcon.icon_up_arrow_2:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe0c6;</Typography>
            break;
        case fontIcon.icon_user_profile_in_circle_solid:
            comp = <Typography onClick={props.onClick} align='center' style={style}>c</Typography>
            break;
        case fontIcon.icon_forward:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe004;</Typography>
            break;
        case fontIcon.icon_calendar:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe058;</Typography>
            break;
        case fontIcon.icon_views:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe049;</Typography>
            break;
        case fontIcon.icon_pencil:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe0c9;</Typography>
            break;
        case fontIcon.icon_my_post:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe04b;</Typography>
            break;
        case fontIcon.icon_share_app:
            comp = <Typography onClick={props.onClick} align='center' style={style}>C</Typography>
            break;
        case fontIcon.icon_star:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe00f;</Typography>
            break;
        case fontIcon.icon_phone:
            comp = <Typography onClick={props.onClick} align='center' style={style}>J</Typography>
            break;
        case fontIcon.icon_padlock:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe050;</Typography>
            break;
        case fontIcon.icon_tnc:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe0ad;</Typography>
            break;
        case fontIcon.icon_hamburger:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe005;</Typography>
            break;
        case fontIcon.icon_phone_2:
            comp = <Typography onClick={props.onClick} align='center' style={style}>&#xe0d2;</Typography>
            break;


        default:
            comp = null
            break;
    }

    return comp;
}