export const color = {
    transparent: "#ffffff00",
    white: "#ffffff",
    black: "#000000",

    white_87: "#ffffffde",
    white_80: "#ffffffcc",
    white_60: "#ffffff99",
    white_40: "#ffffff66",
    white_38: "#ffffff61",
    white_20: "#ffffff33",
    black_87: "#000000de",
    black_80: "#000000cc",
    black_60: "#00000099",
    black_40: "#00000066",
    black_38: "#00000061",
    black_20: "#00000033",

    gray100_b: "#f5f5f5",
    gray200_b: "#eeeeee",
    gray300_b: "#e0e0e0",
    gray400_b: "#bdbdbd",
    gray500_b: "#9e9e9e",
    gray600_w: "#757575",
    gray700_w: "#616161",
    gray800_w: "#424242",
    gray900_w: "#212121",

    orange100_b: "#ffe0b2",
    orange200_b: "#ffcc80",
    orange300_b: "#ffb74d",
    orange400_b: "#ffa726",
    orange500_b: "#ff9800",
    orange600_b: "#fb8c00",
    orange700_b: "#f57c00",
    orange800_b: "#ef6c00",
    orange900_b: "#e65100",

    d_orange100_b: "#ffccbc",
    d_orange200_b: "#ffab91",
    d_orange300_b: "#ff8a65",
    d_orange400_b: "#ff7043",
    d_orange500_b: "#ff5722",
    d_orange600_b: "#f4511e",
    d_orange700_b: "#e64a19",
    d_orange800_b: "#d84315",
    d_orange900_w: "#bf360c",

    green100_b: "#c8e6c9",
    green200_b: "#a5d6a7",
    green300_b: "#81c784",
    green400_b: "#66bb6a",
    green500_b: "#4caf50",
    green600_b: "#43a047",
    green700_b: "#388e3c",
    green800_w: "#2e7d32",
    green900_w: "#1b5e20",

    indigo100_b: "#c5cae9",
    indigo200_b: "#9fa8da",
    indigo300_b: "#7986cb",
    indigo400_w: "#5c6bc0",
    indigo500_w: "#3f51b5",
    indigo600_w: "#3949ab",
    indigo700_w: "#303f9f",
    indigo800_w: "#283593",
    indigo900_w: "#1a237e",

    blue100_b: "#bbdefb",
    blue200_b: "#90caf9",
    blue300_b: "#64b5f6",
    blue400_b: "#42a5f5",
    blue500_b: "#2196f3",
    blue600_b: "#1e88e5",
    blue700_w: "#1976d2",
    blue800_w: "#1565c0",
    blue900_w: "#0d47a1",

    l_blue100_b: "#b3e5fc",
    l_blue200_b: "#81d4fa",
    l_blue300_b: "#4fc3f7",
    l_blue400_b: "#29b6f6",
    l_blue500_b: "#03a9f4",
    l_blue600_b: "#039be5",
    l_blue700_b: "#0288d1",
    l_blue800_w: "#0277bd",
    l_blue900_w: "#01579b",

    red: "#ff0000",
    error: "#b00020",
    m_orange: "#ffa500",
    myasl_orange: "#ff4400",
    myasl_blue: "#87CEEB",
    myasl_grey: "#9f9f9f",
    myasl_grey_light: "#d3d3d3",
    myasl_green: "#2c9100",
    color_button_bg: "#1D4976",

    color_post_tab_color: "#E9912B",
    color_search_tab_color: "#F5FAD8",
    color_home_search: "#06AAF6",
    color_home_post: "#E46C0B",
    colorPrimary: "#125688",

    colorAccent: "#8BC34A",

    blue: "#1976d2",
    test1: "#005489",
    test2: "#007FCE",
    box_zero_normal: "#ffffff",
    box_zero_selected: "#e4b659",
    box_one_normal: "#f2dbad",
    box_one_selected: "#e4b659",
    box_two_normal: "#9996F5",
    box_two_selected: "#7270b7",
    box_three_normal: "#a3febd",
    box_three_selected: "#46fd79",
    box_four_normal: "#d3d3d3",
    box_four_selected: "#9f9f9f",
    box_five_normal: "#68eaf2",
    box_five_selected: "#91d5e5",
    m_light: "#ff471a",
    navigationItem: "#00bbff",
    navigationItemText: "#9f9f9f",
    promo_code_bg: "#ffd3d3",
    organic: "#0F9D58",
    rfree: "#F4B400",
    fpo: "#4285F4",


    emphasis_high: "#000000de",
    emphasis_medium: "#00000099",
    emphasis_low: "#00000061",
    primary: "#3f51b5",
    primary_dark: "#303f9f",
    primary_light: "#c5cae9",
    accent: "#ff5722",
    accent_dark: "#f57c00",
    primary_text: "#212121",
    secondary_text: "#757575",

    color_button_selected: "#f57c00",
    colorPrimaryDark: "#125688",
    textColorPrimary: "#ffffff",
    windowBackground: "#ffffff",
    navigationBarColor: "#000000",
    gray: "#d3d3d3",
}