import axios from 'axios'
import { Log } from '../utils';
import { apiConst } from '../constants';

const baseUrl = 'http://myasl.in:8080/myasl/' // production
// const baseUrl = 'http://139.162.22.144:8080/myasl/' // lenode
// const baseUrl = 'http://203.134.248.165:8080/myasl/' // static
// const baseUrl = 'http://192.168.31.142:8080/myasl/' // local
// const baseUrl = 'http://192.168.94.1:8080/myasl/' // local
// const baseUrl = 'http://192.168.160.1:8080/myasl/' // local
// const baseUrl = 'http://192.168.31.182:8080/myasl/' // local

export const apiInterface = axios.create({
    baseURL: baseUrl,
    // headers: { 'Content-Type': 'text/html; charset=UTF-8'}
    // headers: { 'Content-Type': 'application/json'}
    // headers: { 'pinnumber': '-1', 'uid': '-1', 'lang': '' },

})

apiInterface.interceptors.response.use((response) => {

    Log.info(response)

    return response
})


export const apiUrl = {
    postCount: 'security/dashbstat',
    getProd: 'prod/getpc',
    getLoc: 'loc/getloc',
    refreshUser: 'user/refresh',
    postListing: 'posting/search',
    postDetail: 'posting/viewfull',
    submitPost: 'posting/add',
    myPost: 'posting/mypost',
    deletePost: 'posting/delete',
    prodImages: (pid) => {
        pid = pid.substring(0, pid.length - 2) + '00'
        // return baseUrl + 'bin/pi/m_' + pid + '.jpg'
        return 'http://marketyard.org/img/product/m_' + pid + '.jpg'
    },
    shopImages: (shopId) => {
        // return baseUrl + 'bin/si/m_shop' + shopId + '.jpg'
        return 'http://marketyard.org/img/product/m_shop' + shopId + '.jpg'
    },
    postDetailImage: (haspic, id, pid, pt) => {

        if (haspic) {

            if (pt === apiConst.ptSell) {
                return baseUrl + 'bin/sp/' + id + '.jpg'
            }
            else if (pt === apiConst.ptGiveOnRent) {
                return baseUrl + 'bin/rp/' + id + '.jpg'
            }
            else {
                return apiUrl.prodImages(pid)
            }
        }
        else {

            return apiUrl.prodImages(pid)
        }
    },
    userImage: (uid) => {
        return baseUrl + 'bin/dp/' + uid + '.jpg'
    },
    registerUser: 'user/gatewayreg',
    // getKisanPass: 'http://greencloud.kisanlab.com/v1/issueinviteswithinvitationlink.php',
    getKisanPass: 'https://greencloud.kisan.in/v1/issueinviteswithinvitationlink.php',

}

