import React from 'react'
import { Typography, TextField, Button } from '@material-ui/core'
import { string, color } from '../res'
import { apiInterface, apiUrl } from '../network'
import { Log } from '../utils';
import { MyCircularProgress, ImageWithComponentFallback } from '../components'
import kisan_eng from '../res/img/kisan_eng.png'
import kisan_hin from '../res/img/kisan_hin.png'
import kisan_mar from '../res/img/kisan_mar.png'
import ak_kisan_eng from '../res/img/m_default_gray_leaf.jpg'
import ak_kisan_hin from '../res/img/m_default_gray_leaf.jpg'
import ak_kisan_mar from '../res/img/m_default_gray_leaf.jpg'
import { langCode } from '../constants'


// const tnc_eng = 'Disclaimer: Your contact details will be shared with Kisan Forum Pvt. Ltd. for further registration process & you will be registered as a Marketyard user. By clicking "SUBMIT" you agree to accept all Terms & Conditions. *'
// const tnc_hin = 'अस्वीकरण: आपका संपर्क विवरण किसान फोरम प्रा. लि. के साथ आगे की पंजीकरण प्रक्रिया के लिए साझा किया जाएगा और आपको एक मार्केटयार्ड उपयोगकर्ता के रूप में पंजीकृत किया जाएगा. "जमा करें" इस बटन पर क्लिक करके आप सभी नियम और शर्तें स्वीकार करने के लिए सहमत हैं. *'
// const tnc_mar = 'अस्वीकरण: आपले संपर्क तपशील किसान फोरम प्रा. लि. बरोबर पुढील नोंदणी प्रक्रियेसाठी सामायिक केले जातील आणि आपण मार्केटयार्ड वापरकर्ता म्हणून नोंदणीकृत असाल. "सबमिट करा" या बटनावर क्लिक करून आपण सर्व नियम व अटी स्वीकारण्यास सहमती देत आहात. *'

const tnc_eng = 'Disclaimer: You will be registered as a Marketyard user. Request you to Download "MARKETYARD APP" from Google Play Store. By clicking "SUBMIT" you agree to accept all Terms & Conditions. *'
const tnc_hin = 'अस्वीकरण: आपको मार्केटयार्ड उपयोगकर्ता के रूप में पंजीकृत किया जाएगा. आपसे Google Play Store से "MARKETYARD APP" डाउनलोड करने का अनुरोध. "जमा करें" इस बटन पर क्लिक करके आप सभी नियम और शर्तें स्वीकार करने के लिए सहमत हैं. *'
const tnc_mar = 'अस्वीकरण: आपण मार्केटयार्ड वापरकर्ता म्हणून नोंदणीकृत असाल. गूगल प्ले स्टोअर वरून "मार्केटयार्ड अ‍ॅप" डाउनलोड करण्याची विनंती. "सबमिट करा" या बटनावर क्लिक करून आपण सर्व नियम व अटी स्वीकारण्यास सहमती देत आहात. *'

const ak_tnc_eng = 'Eng'
const ak_tnc_hin = 'hin'
const ak_tnc_mar = 'mar'

const english = 'english'
const hindi = 'hindi'
const marathi = 'marathi'

export default class KisanPass extends React.Component {

    state = {
        firstName: '',
        lastName: '',
        mobileNo: '',
        state: '',
        district: '',
        fnErrorMessage: '',
        lnErrorMessage: '',
        mnErrorMessage: '',
        language: marathi,
        loading: false,
    }

    handleNumberInput = (name) => event => {

        let a = 0

        if (name === 'mobileNo') {
            if (!event.target.value.length > 10) {
                a = event.target.validity.valid ? event.target.value : this.state[name]
            }
            else {
                a = event.target.value.substring(0, 10)
            }
        } else {
            if (!event.target.value.length > 20) {
                a = event.target.validity.valid ? event.target.value : this.state[name]
            }
            else {
                a = event.target.value.substring(0, 20)
            }
        }

        this.setState({
            [name]: a,
        })

    }

    handleSubmitClick = () => {

        Log.verbose('KISAN', 'handleSubmitClick')
        const { firstName, lastName, mobileNo, language } = this.state

        const lang = language === marathi ? langCode.mar : language === hindi ? langCode.hin : langCode.eng

        if (firstName !== '' && lastName !== '' && mobileNo !== '' && mobileNo.length === 10 &&
            (mobileNo.startsWith('6') || mobileNo.startsWith('7') || mobileNo.startsWith('8') || mobileNo.startsWith('9'))) {

            Log.verbose('KISAN', 'handleSubmitClick >> makeRequest')
            this.setState({
                fnErrorMessage: '',
                lnErrorMessage: '',
                mnErrorMessage: '',
                loading: true,
            })

            this.makeRegisterRequest()
            // this.makeKisanRequest('test')

        } else {
            Log.verbose('KISAN', 'handleSubmitClick >> error')
            this.setState({
                fnErrorMessage: firstName === '' ? string[lang].please_enter_your_first_name : '',
                lnErrorMessage: lastName === '' ? string[lang].please_enter_your_last_name : '',
                mnErrorMessage: mobileNo === '' ? string[lang].please_enter_your_mobile_no : mobileNo.length !== 10 ? string[lang].please_enter_a_valid_mobile_no :
                    (mobileNo.startsWith('6') || mobileNo.startsWith('7') || mobileNo.startsWith('8') || mobileNo.startsWith('9')) ? '' : string[lang].please_enter_a_valid_mobile_no,
            })

        }


    }

    makeRegisterRequest = () => {

        const { firstName, lastName, mobileNo } = this.state
        const { isAk } = this.props

        const payLoad = {
            mobilenumber: mobileNo,
            username: firstName,
            lastname: lastName,
            // promocode: isAk ? "kisanpass2019_ak" : "kisanpass2019_limited",
            promocode: "kisanpass",
            state: "MH",
            // pincode:"123456" 
        }

        apiInterface.post(apiUrl.registerUser, payLoad)
            .then((res) => {

                Log.verbose('KISAN', 'makeRegisterRequest >> res: ' + res)
                // this.makeKisanRequest('registerSuccess')
            })
            .catch((err) => {
                Log.verbose('KISAN', 'makeRegisterRequest >> err: ' + err)
                // this.makeKisanRequest('registerFailure')
            })
    }

    makeKisanRequest = (status) => {

        Log.verbose('KISAN', 'makeKisanRequest >> ' + status)

        const { firstName, lastName, mobileNo, state, district, language } = this.state

        const payLoad = {

            "app_key": "9E1E6222E53CF2316B1AA51D57345",
            "entryType": "Single Entry",
            "eventCode": "KISAN19",
            // lang: english/marathi/hindi,
            "lang": language,
            // passType: unlimitedexhibitorinvite / exhibitorinvite,
            "passType": "exhibitorinvite",
            "people": [
                {
                    "first_name": firstName, "last_name": lastName, "mobile": mobileNo, "selectedLang": language,
                }
            ],
            // "sessionId": "bXJhbmlsbGFkZTE1MjY5NzMxODU3MzlfMTU3MzExMDUzNTA3Mg==",//
            "sessionId": "bXJhbmlsbGFkZTE1MjY5NzMxODU3MzlfMTU3MzEyNDI2ODEyMA==",//NEW MKTYD
            // "source": "exhibitor",
            "source": "web",
            "type": "exhibitor"

        }



        // const payLoad = {

        //     "app_key": "9E1E6222E53CF2316B1AA51D57345",
        //     "entryType": "Single Entry",
        //     "eventCode": "KISAN19",
        //     "lang": "english",
        //     "passType": "unlimitedexhibitorinvite",
        //     "people": [
        //         {
        //             "first_name": firstName, "last_name": lastName, "mobile": mobileNo, "selectedLang": "english"
        //         }
        //     ],
        //     "sessionId": "aGFyc2hwYXRpbDE1NjA0MzE4MDU3MjBfMTU3Mjk0NzQ0Njk5MQ==",
        //     "source": "exhibitor",
        //     "type": "exhibitor"

        // }

        apiInterface.post(apiUrl.getKisanPass, payLoad, { headers: { 'Content-Type': 'application/json' } })
            .then((res) => {
                // {"timeInMillis":1130,"success":true,"message":"passes issued
                // successfully","responseCode":0,"summary":{"passes":1,"pass_error_visitors":0,"valid_visitors":1,"invalid_visitors":0,"duplicate_visitors":0,"invitelink":"GREENPASS_INVITE_LINK_BASE_URLjs5lv"}}

                // {"timeInMillis":100,"success":false,"message":"Invalid session id","responseCode":104}

                // {"timeInMillis":162,"success":false,"message":"You cannot use same mobile number to which you have already sent the invite","responseCode":153}

                // {"timeInMillis":1408,"success":true,"message":"passes issued successfully","responseCode":0,"summary":{"passes":1,"pass_error_visitors":0,"valid_visitors":1,"invalid_visitors":0,"duplicate_visitors":0,"invitelink":"http:\/\/kisan.net\/pass\/wdgty"}}

                if (res.data.success) {
                    this.launchKisanPage(res.data.summary.invitelink)
                } else {
                    alert(res.data.message)
                }

                this.setState({
                    loading: false,
                })


            })
            .catch((err) => {
                Log.verbose('KISAN', 'makeKisanRequest >> err: ' + err)
                alert('ERROR...Please try again later !')

                this.setState({
                    loading: false,
                })
            })
    }

    launchKisanPage = (kisanLink) => {
        Log.verbose('KISAN', 'launchKisanPage >> ' + kisanLink)
        window.open(kisanLink, '_self')
    }

    handleMarathiClick = () => {

        this.setState({ language: marathi })
    }

    handleHindiClick = () => {
        this.setState({ language: hindi })
    }

    handleEnglishClick = () => {
        this.setState({ language: english })
    }

    render() {

        const { firstName, lastName, mobileNo, state, district, fnErrorMessage, lnErrorMessage, mnErrorMessage, language, loading } = this.state

        const { isAk } = this.props

        console.log("isAk >> " + isAk)


        let lang = langCode.mar
        let img = isAk ? ak_kisan_mar : kisan_mar
        let tnc = isAk ? ak_tnc_mar : tnc_mar

        if (language === hindi) {
            lang = langCode.hin
            img = isAk ? ak_kisan_hin : kisan_hin
            tnc = isAk ? ak_tnc_hin : tnc_hin

        } else if (language === english) {
            lang = langCode.eng
            img = isAk ? ak_kisan_eng : kisan_eng
            tnc = isAk ? ak_tnc_eng : tnc_eng
        }

        // const lang = language === marathi ? langCode.mar : language === hindi ? langCode.hin : langCode.eng
        // const img = language === marathi ? kisan_mar : language === hindi ? kisan_hin : kisan_eng
        // const tnc = language === marathi ? tnc_mar : language === hindi ? tnc_hin : tnc_eng

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>

                {/* <ImageWithComponentFallback component={<Typography variant="h4" component="h3" style={{ alignSelf: 'center', padding: '15px 10px', fontWeight: 'bold', color: color.green500_b }}>KISAN PASS</Typography>}
                    src={''}
                    style={{ width: '100%', height: '16%', margin: '5px 0px 10px 0px' }}
                /> */}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Typography onClick={this.handleEnglishClick} align="justify" style={{ fontSize: 'small', color: language === english ? color.accent : color.gray500_b, padding: '10px' }}>English</Typography>
                    <Typography onClick={this.handleHindiClick} align="justify" style={{ fontSize: 'small', color: language === hindi ? color.accent : color.gray500_b, padding: '10px' }}>हिन्दी</Typography>
                    <Typography onClick={this.handleMarathiClick} align="justify" style={{ fontSize: 'small', color: language === marathi ? color.accent : color.gray500_b, padding: '10px' }}>मराठी</Typography>
                </div>
                {/* <img style={{ width: '100%', height: '50%', margin: '5px 0px 10px 0px' }} src={img} /> */}

                <Input fieldName={'firstName'} fieldValue={firstName} handleNumberInput={this.handleNumberInput} placeHolder={string[lang].first_name}
                    error={fnErrorMessage.trim().length > 0 ? true : false}
                    errorMessage={fnErrorMessage}
                    required={true} />
                <Input fieldName={'lastName'} fieldValue={lastName} handleNumberInput={this.handleNumberInput} placeHolder={string[lang].last_name}
                    error={lnErrorMessage.trim().length > 0 ? true : false}
                    errorMessage={lnErrorMessage}
                    required={true} />
                <Input fieldName={'mobileNo'} fieldValue={mobileNo} handleNumberInput={this.handleNumberInput} placeHolder={string[lang].mobile_no}
                    error={mnErrorMessage.trim().length > 0 ? true : false}
                    errorMessage={mnErrorMessage}
                    required={true} />
                {/* <Input fieldName={'state'} fieldValue={state} handleNumberInput={this.handleNumberInput} placeHolder={string[lang].State} /> */}
                {/* <Input fieldName={'district'} fieldValue={district} handleNumberInput={this.handleNumberInput} placeHolder={string[lang].District} /> */}

                <Typography align="justify" style={{ fontSize: 'xx-small', color: color.gray500_b, marginBottom: '20px' }}>{tnc}</Typography>

                <Button variant="contained" color="secondary" size="medium" onClick={this.handleSubmitClick}
                    style={{ width: '200px', alignSelf: 'center' }} >{string[lang].submit}</Button>

                {loading && <MyCircularProgress fullScreenNew />}
            </div>)
    }
}

function Input(props) {

    return <TextField
        error={props.error}
        helperText={props.error ? props.errorMessage : " "}
        required={props.required}
        style={{ flex: '1' }}
        id="outlined-dense"
        value={props.fieldValue}
        onChange={props.handleNumberInput(props.fieldName)}
        margin="dense"
        variant="outlined"
        label={props.placeHolder}
        type={props.fieldName === 'mobileNo' ? "number" : "text"}
    />
}