import React from 'react'
import { Log, IntentUtils } from '../utils';
import { Typography, Paper, Divider, Button } from '@material-ui/core';
import { FontIcon, MyCircularProgress, ImageWithFallbacks, ImageWithComponentFallback } from '../components'
import { color as mColor, fontIcon, string } from '../res'
import { apiInterface, apiUrl } from '../network'
import defaultImg from '../res/img/m_default_gray_leaf.jpg'
import '../App.css'
import StarRatingComponent from 'react-star-rating-component'
import { UserContext } from '../context';
import { langCode } from '../constants';



export default class PostDetail extends React.Component {

    state = {
        loading: true,
        post: {}
    }

    componentDidMount() {
        this.makeRequest()
    }

    makeRequest = () => {

        const payLoad = { "posttype": this.props.pt, "id": this.props.id }

        apiInterface.post(apiUrl.postDetail, payLoad)
            .then((res) => {

                this.setState({
                    loading: false,
                    post: res.data.data[0],
                })


            })
            .catch((err) => {
                Log.verbose(err)
            })

    }

    render() {

        const { loading, post } = this.state

        return (!loading ? <PostCard post={post} {...this.props} /> : <MyCircularProgress fullScreen />)
    }
}

class PostCard extends React.Component {

    static contextType = UserContext
    constructor(props, context) {
        super(props, context)

    }

    handleShareClick = () => {
        Log.verbose(this.props)

        if (window.AppInterface) {

            const webCallbackPayload = { type: 'share', message: 'http://m.marketyard.org' + this.props.location.pathname + this.props.location.search }
            window.AppInterface.webCallback(JSON.stringify(webCallbackPayload))
        }

        window.open(IntentUtils.getSharePostIntent(window.location.href), '_parent')
    }
    render() {

        //         "id":"1533020821377",
        // "recdate":"2018-07-31 12:37:01.0",
        // "totalviews":"49",
        // "likes":"0",
        // "price":"55",
        // "qty":"22",
        // "uom":"Kg",
        // "uom2":"Kg",
        // "comment":"",
        // "rating":"0.0",
        // "haspic":"false",
        // "locename":"Ahmednagar | Rahuri | Rahuri KH",
        // "loclname":"अहमदनगर | राहुरी | राहुरी KH",
        // "prod":"ब्लॅक चोकेबेरी",
        // "prod2":"Black Chokeberry",
        // "username":"Santosh",
        // "lastname":"Yelpale",
        // "mobilenumber":"9403501284",
        // "ratedby":"0",
        // "uid":"1533020633375",
        // isfpo: "true"
        // isorganic: "true"
        // isrfree: "false"
        let { id, recdate, totalviews, likes, price, qty, uom, uom2, comment,
            rating, haspic, locename, loclname, prod, prod2, username, lastname, mobilenumber, ratedby, uid,
            isorganic, isrfree, isfpo, priceuom, qtyuom, pid } = this.props.post

        pid = '110200'
        const { lang } = this.context

        isorganic = isorganic === 'true'
        isrfree = isrfree === 'true'
        isfpo = isfpo === 'true'

        const date = new Date(recdate)
        const postDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()

        return (

            <Paper style={{ display: 'flex', flexDirection: 'column' }}>

                <div style={{ backgroundColor: mColor.gray400_b, padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ backgroundColor: mColor.gray400_b, padding: 10, display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ flex: '1', color: mColor.emphasis_high, fontFamily: 'bold', fontSize: '0.9rem', fontWeight: '600' }}>{lang === langCode.eng ? prod2 : prod}</Typography>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {isorganic && <Typography style={{ borderRadius: '2px', alignSelf: 'center', padding: '1px 5px', marginRight: '5px', backgroundColor: mColor.organic, color: mColor.white, fontFamily: 'regular', fontSize: '0.7rem', fontWeight: '200' }}>{string[lang].organic}</Typography>}
                            {isrfree && <Typography style={{ borderRadius: '2px', alignSelf: 'center', padding: '1px 5px', marginRight: '5px', backgroundColor: mColor.rfree, color: mColor.white, fontFamily: 'regular', fontSize: '0.7rem', fontWeight: '200' }}>{string[lang].residue_free}</Typography>}
                            {isfpo && <Typography style={{ borderRadius: '2px', alignSelf: 'center', padding: '1px 5px', backgroundColor: mColor.fpo, color: mColor.white, fontFamily: 'regular', fontSize: '0.7rem', fontWeight: '200' }}>{string[lang].fpo}</Typography>}
                        </div>
                        {comment && comment.length > 0 && <Typography style={{ color: mColor.emphasis_medium }}>{comment}</Typography>}
                    </div>
                    <FontIcon onClick={this.handleShareClick} style={{ color: mColor.emphasis_medium, fontSize: '1.2rem', padding: '10px', alignSelf: 'baseline' }} fontIcon={fontIcon.icon_forward} />
                </div>


                <div style={{ padding: 10, display: 'flex', padding: '10px', flexDirection: 'row', alignItems: 'center' }}>
                    <ImageWithFallbacks style={{ width: '60%', height: '60%', border: '1px solid #e2e2e2', marginRight: '10px' }}
                        src={apiUrl.postDetailImage(haspic, id, pid, this.props.pt)} fallbacks={[apiUrl.prodImages(pid), defaultImg]} />

                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>

                        <Typography style={{ flex: '1', color: mColor.emphasis_medium }}>{string[lang].price_colon}</Typography>
                        <Typography style={{ flex: '1', color: mColor.accent, fontSize: '1rem' }}>{string[langCode.eng].rupee_symbol + ' ' + price + '/' + (string[lang].uom[priceuom] ? string[lang].uom[priceuom] : uom2)}</Typography>
                        <Divider />
                        <Typography style={{ flex: '1', color: mColor.emphasis_medium }}>{string[lang].quantity_colon}</Typography>
                        <Typography style={{ flex: '1', color: mColor.accent, fontSize: '1rem' }}>{qty + ' ' + (string[lang].uom[qtyuom] ? string[lang].uom[qtyuom] : uom)}</Typography>
                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FontIcon style={{ color: mColor.emphasis_medium, fontSize: '1rem', padding: '2px 5px' }} fontIcon={fontIcon.icon_calendar} />
                            <Typography style={{ flex: '1', color: mColor.accent, fontSize: '1rem', marginLeft: '5px' }}>{postDate}</Typography>
                        </div>
                        <Divider />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FontIcon style={{ color: mColor.emphasis_medium, fontSize: '1rem', padding: '2px 5px' }} fontIcon={fontIcon.icon_views} />
                            <Typography style={{ flex: '1', color: mColor.accent, fontSize: '1rem', marginLeft: '5px' }}>{totalviews}</Typography>
                        </div>
                    </div>

                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 10px' }}>

                    <ImageWithComponentFallback component={<FontIcon style={{ color: mColor.gray300_b, fontSize: '4rem', padding: '5px' }} fontIcon={fontIcon.icon_user_profile_in_circle_solid} />}
                        src={apiUrl.userImage(uid)}
                        style={{ width: '20%', height: '20%', border: '0px solid #e2e2e2', marginRight: '10px', borderRadius: '100px' }}
                    />

                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>

                        <Typography style={{ flexGrow: '1', color: mColor.emphasis_high, fontFamily: 'bold', fontSize: '0.9rem', fontWeight: '600' }}>{username + ' ' + lastname}</Typography>
                        <Typography style={{ color: mColor.emphasis_medium, fontFamily: 'regular', fontSize: '0.9rem', fontWeight: '500' }}>{lang === langCode.eng ? locename : loclname}</Typography>

                        {/* <Rating
                            value={rating}
                            max={5}
                            readOnly={false}
                            style={{ padding: '1px' }}
                        /> */}

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div>
                                <StarRatingComponent
                                    name="rate1"
                                    starCount={5}
                                    value={rating}
                                    editing={false}
                                />
                            </div>
                            <Typography style={{ color: mColor.emphasis_medium, fontSize: '0.8rem', fontWeight: '500', marginLeft: '5px' }}>{'(' + ratedby + ')'}</Typography>
                        </div>
                    </div>
                </div>

                <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '0px 10px 10px 10px',
                    justifyContent: 'space-evenly'
                }}>

                    {/* <Button variant="outlined" color="primary" >{'Rate User'}</Button> */}
                    <div style={{ flexGrow: '1' }}></div>
                    <Button variant="contained" color="primary" href={"tel: " + mobilenumber}>{string[lang].Call}</Button>
                    {/* <Typography style={{ color: mColor.emphasis_high }} onClick={this.handleMobileClick}>{mobilenumber}</Typography> */}
                </div>
            </Paper>
        );
    }

}

