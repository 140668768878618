export const apiConst = {

    ptBuy: '1',
    ptSell: '2',
    ptGiveOnRent: '3',
    ptGetOnRent: '4',

    rentable: '1',

    topicNews: '1',
    topicQna: '2',
    topicVdo: '3',
    topicJobs: '4',
    topicGovtSchemes: '5',
    topicEvents: '6',

    sbDate: '1',
    sbPrice: '2',
    sbRating: '3',

    sbOrderAsc: '1',
    sbOrderDsc: '2',
}