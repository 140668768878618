export const Log = {

    enableLog: false,

    verbose: (tag, message) => {
        if (Log.enableLog && (message ? console.log(tag, message) : console.log(tag)));
    },

    error: (tag, message) => {
        if (Log.enableLog && (message ? console.error(tag, message) : console.error(tag)));
    },

    debug: (tag, message) => {
        if (Log.enableLog && (message ? console.debug(tag, message) : console.debug(tag)));
    },

    info: (tag, message) => {
        if (Log.enableLog && (message ? console.info(tag, message) : console.info(tag)));
    },

    warn: (tag, message) => {
        if (Log.enableLog && (message ? console.warn(tag, message) : console.warn(tag)));
    }
}
