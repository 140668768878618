import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { color } from '../res';
import { Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(1),
        color: color.primary,
        alignSelf: 'center',
    },
}));

export default function MyCircularProgress(props) {
    const classes = useStyles();


    if (props.fullScreen) {

        return (
            <div style={{ position: 'absolute', top: '50%', left: '50%', marginTop: '-12.5px', marginLeft: '-12.5px' }}>
                <CircularProgress className={classes.progress} size={25} thickness={4} />
            </div>
        )
    }
    else if (props.fullScreenNew) {

        return (
            <Container maxWidth="xs" style={{ position: 'absolute', backgroundColor: '#00000031', margin: '-10px 0px 0px -10px', minHeight: '100vh' }}>
                <div style={{ position: 'absolute', top: '40%', left: '50%', marginTop: '-12.5px', marginLeft: '-12.5px' }}>
                    <CircularProgress className={classes.progress} size={25} thickness={4} />
                </div>
            </Container>
        )
    }
    else {

        return (
            <div style={{ position: 'absolute', left: '50%', marginTop: '-12.5px', marginLeft: '-12.5px' }}>
                <CircularProgress className={classes.progress} size={25} thickness={4} />
            </div>
        )
    }
}