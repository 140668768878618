import React from 'react'
import StarRatingComponent from 'react-star-rating-component'
import { NavLink } from 'react-router-dom'
import { apiConst, pathConst, langCode } from '../constants'
import { Typography, GridList, GridListTile, Paper, Container, Button, Dialog, FormControl, Select, OutlinedInput } from '@material-ui/core';
import { FontIcon, MyCircularProgress, ImageWithFallbacks, ImageWithComponentFallback, ToolBar } from '../components'
import { color as mColor, fontIcon, string } from '../res'
import { apiInterface, apiUrl } from '../network'
import { UserContext } from '../context'
import { InfiniteScrollExtend } from '../components/InfiniteScrollExtend'
import defaultImg from '../res/img/m_default_gray_leaf.jpg'
import '../App.css'
import { LocationUtils, Log } from '../utils'


const userAvatarColors = [mColor.primary, mColor.accent, mColor.m_orange, mColor.green600_b]

const fetchSize = 20

export default class PostListing extends React.Component {

    static contextType = UserContext

    constructor(props, context) {
        super(props, context)

        Log.verbose('getLocDetails', context.lid.substring(0, 2))
        // const loc = LocationUtils.getLocDetails(context.lid, context.lename, context.llname)
        const loc = null

        this.state = {
            postList: [],
            loading: true,
            lid: '999999999',
            sortBy: apiConst.sbDate,
            sortByOrder: apiConst.sbOrderDsc,
            fetchCount: 0,
            isOrganic: false,
            isRFree: false,
            isFpo: false,
            makeRequest: false,
            loadMore: true,
            message: '',
            width: '-1',
            height: '-1',

            loading: false,


            prodList: [],
            tempProdList: [],
            prodValue: -1,
            tempProdValue: -1,

            stateList: loc && loc.state ? [loc.state] : [],
            districtList: loc && loc.district ? [loc.district] : [],
            talukaList: loc && loc.taluka ? [loc.taluka] : [],

            tempStateList: loc && loc.state ? [loc.state] : [],
            tempDistrictList: loc && loc.district ? [loc.district] : [],
            tempTalukaList: loc && loc.taluka ? [loc.taluka] : [],

            stateValue: loc && loc.state ? 0 : -1,
            districtValue: loc && loc.district ? 0 : -1,
            talukaValue: loc && loc.taluka ? 0 : -1,

            tempStateValue: loc && loc.state ? 0 : -1,
            tempDistrictValue: loc && loc.district ? 0 : -1,
            tempTalukaValue: loc && loc.taluka ? 0 : -1,

            openVarientDropdown: false,
            openStateDropdown: false,
            openDistrictDropdown: false,
            openTalukaDropdown: false,

            showSelectStateError: false

        }

        // window.addEventListener('scroll', this.windowDimens.bind(this))
    }

    componentDidMount() {
        // this.makeRequest('1')
    }

    makeRequest = (pageNo) => {

        // if (!pageNo) {

        //     pageNo = 1
        // }

        const { lid,
            sortBy,
            sortByOrder,
            isOrganic,
            isRFree,
            isFpo,
            prodList, prodValue } = this.state


        const pid = (prodList && prodList.length > 0 && prodValue !== -1) ? prodList[prodValue].pid : this.props.pid

        const payLoad = { "posttype": this.props.pt, "pid": pid, "lid": lid, "sortby": sortBy, "sortbyorder": sortByOrder, "fetchcount": pageNo, "fetchsize": fetchSize, "isorganic": isOrganic, "isrfree": isRFree, "isfpo": isFpo }


        apiInterface.post(apiUrl.postListing, payLoad)
            .then((res) => {

                if (res.data.status.returncode === '0') {

                    this.setState({
                        loading: false,
                        loadMore: false,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        postList: [...this.state.postList, ...res.data.data],
                        loadMore: res.data.data.length === fetchSize
                    })
                }


            })
            .catch((err) => {
            })

    }

    makeVarientProductListRequest = () => {

        const pid = this.props.pid
        const payLoad = { pinnumber: '-1', uid: '-1' }

        if (pid) {
            payLoad.pid = pid
        }

        apiInterface.post(apiUrl.getProd, payLoad)
            .then((res) => {

                this.setState({
                    loading: false,
                    tempProdList: res.data.data,
                    tempStateList: [],
                    tempDistrictList: [],
                    tempTalukaList: [],
                    tempStateValue: -1,
                    tempDistrictValue: -1,
                    tempTalukaValue: -1,
                    openVarientDropdown: true
                })

            })
            .catch((err) => {
            })
    }

    makeLocationRequest = (lid) => {

        Log.verbose('sss', 'makeLocationRequest >> ' + lid)
        this.setState({
            loading: true
        })

        const { tempProdList, tempProdValue } = this.state

        const payLoad = { pinnumber: '-1', uid: '-1' }

        if (lid) {
            payLoad.lid = lid
        }

        const pid = (tempProdList && tempProdList.length > 0 && tempProdValue !== -1) ? tempProdList[tempProdValue].pid : this.props.pid

        payLoad.pid = pid

        apiInterface.post(apiUrl.getLoc, payLoad)
            .then((res) => {

                Log.verbose('sss', lid)
                if (!lid || lid.trim().length === 0) {
                    this.setState({
                        loading: false,
                        tempStateList: res.data.data,
                        tempDistrictList: [],
                        tempTalukaList: [],
                        tempDistrictValue: -1,
                        tempTalukaValue: -1,
                        openStateDropdown: true
                    })
                }
                else if (lid.substring(2, 4) === '00') {
                    this.setState({
                        loading: false,
                        tempDistrictList: res.data.data,
                        tempTalukaList: [],
                        tempTalukaValue: -1,
                        openDistrictDropdown: true
                    })
                }
                else if (lid.substring(4, 6) === '00') {
                    this.setState({
                        loading: false,
                        tempTalukaList: res.data.data,
                        openTalukaDropdown: true
                    })
                }

            })
            .catch((err) => {
            })
    }

    componentDidUpdate(prevProps, prevState) {
        // Log.verbose(TAG, 'componentDidUpdate >> ' + JSON.stringify(prevProps) + ' >> ' + JSON.stringify(prevState))
        // Log.verbose(TAG, 'componentDidUpdate >> ' + JSON.stringify(this.props) + ' >> ' + JSON.stringify(this.state))

        // if (this.state.makeRequest) {

        //     this.setState({
        //         loading: true,
        //         makeRequest: false,
        //         fetchCount: 0
        //     })
        //     // this.makeRequest()
        // }
    }

    handleRecent = () => {
        this.setState({
            sortBy: apiConst.sbDate,
            sortByOrder: this.state.sortBy === apiConst.sbDate ?
                (this.state.sortByOrder === apiConst.sbOrderDsc ? apiConst.sbOrderAsc : apiConst.sbOrderDsc) : apiConst.sbOrderDsc,
            makeRequest: true,
            postList: [],
            fetchCount: this.state.fetchCount === 0 ? -1 : 0,
            loadMore: true
        })
    }

    handlePrice = () => {
        this.setState({
            sortBy: apiConst.sbPrice,
            sortByOrder: this.state.sortBy === apiConst.sbPrice ?
                (this.state.sortByOrder === apiConst.sbOrderDsc ? apiConst.sbOrderAsc : apiConst.sbOrderDsc) : apiConst.sbOrderDsc,
            makeRequest: true,
            postList: [],
            fetchCount: this.state.fetchCount === 0 ? -1 : 0,
            loadMore: true
        })
    }

    handleRating = () => {

        this.setState({
            sortBy: apiConst.sbRating,
            sortByOrder: this.state.sortBy === apiConst.sbRating ?
                (this.state.sortByOrder === apiConst.sbOrderDsc ? apiConst.sbOrderAsc : apiConst.sbOrderDsc) : apiConst.sbOrderDsc,
            makeRequest: true,
            postList: [],
            fetchCount: this.state.fetchCount === 0 ? -1 : 0,
            loadMore: true
        })
    }

    windowDimens = () => {
        var test = window.innerWidthssss

        var w = window.innerWidth
        var h = window.innerHeight


        const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight
        const body = document.body
        const html = document.documentElement

        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight)
        const windowBottom = windowHeight + window.pageYOffset
        if (windowBottom >= docHeight) {

            this.setState({
                message: 'bottom reached', width: w, height: h
            })

        }
        else {
            this.setState({
                message: 'bottom not reached', width: w, height: h
            })
        }

    }

    openApplyFilter = () => {

        this.setState({

            tempProdList: this.state.prodList,
            tempStateList: this.state.stateList,
            tempDistrictList: this.state.districtList,
            tempTalukaList: this.state.talukaList,

            tempProdValue: this.state.prodValue,
            tempStateValue: this.state.stateValue,
            tempDistrictValue: this.state.districtValue,
            tempTalukaValue: this.state.talukaValue,
        })
        this.props.history.push(this.props.location.pathname + this.props.location.search, { ...this.props.location.state, showApplyFilter: true });

    }

    closeApplyFilter = () => {
        window.history.back();
    }

    handleVarientSelection = () => {
        const { tempProdList } = this.state
        if (tempProdList && tempProdList.length > 0) {
            this.setState({
                openVarientDropdown: true
            })

        }
        else {
            this.makeVarientProductListRequest()
        }
    }
    handleStateSelection = () => {

        Log.verbose('sss', 'handleStateSelection')
        const { tempStateList } = this.state
        if (tempStateList && tempStateList.length > 1) {
            this.setState({
                openStateDropdown: true
            })

        }
        else {
            this.makeLocationRequest(null)
        }
    }


    handleDistrictSelection = () => {
        Log.verbose('sss', 'handleDistrictSelection')
        const { tempStateList, tempDistrictList, tempStateValue } = this.state
        if (tempDistrictList && tempDistrictList.length > 1) {
            this.setState({
                openDistrictDropdown: true
            })

        }
        else {
            this.makeLocationRequest(tempStateList[tempStateValue].lid)
        }
    }

    handleTalukaSelection = () => {
        Log.verbose('sss', 'handleTalukaSelection')
        const { tempDistrictList, tempTalukaList, tempDistrictValue } = this.state
        if (tempTalukaList && tempTalukaList.length > 1) {
            this.setState({
                openTalukaDropdown: true
            })

        }
        else {
            this.makeLocationRequest(tempDistrictList[tempDistrictValue].lid)
        }
    }


    closeVarientSelection = () => {

        Log.verbose('sss', 'closeVarientSelection')
        this.setState({
            openVarientDropdown: false
        })
    }

    closeStateSelection = () => {

        Log.verbose('sss', 'closeStateSelection')
        this.setState({
            openStateDropdown: false
        })
    }

    closeDistrictSelection = () => {
        Log.verbose('sss', 'closeDistrictSelection')
        this.setState({
            openDistrictDropdown: false
        })
    }

    closeTalukaSelection = () => {
        Log.verbose('sss', 'closeTalukaSelection')
        this.setState({
            openTalukaDropdown: false
        })
    }

    handleChange = (name) => event => {

        Log.verbose('sss', 'handleChange ' + name)


        if (name === 'tempProdValue') {

            this.setState({
                ...this.state,
                tempProdValue: event.target.value,

                tempStateValue: event.target.value !== this.state.tempProdValue ? -1 : this.state.tempStateValue,
                tempDistrictValue: event.target.value !== this.state.tempProdValue ? -1 : this.state.tempDistrictValue,
                tempTalukaValue: event.target.value !== this.state.tempProdValue ? -1 : this.state.tempTalukaValue,

                tempStateList: event.target.value !== this.state.tempProdValue ? [] : this.state.tempStateList,
                tempDistrictList: event.target.value !== this.state.tempProdValue ? [] : this.state.tempDistrictList,
                tempTalukaList: event.target.value !== this.state.tempProdValue ? [] : this.state.tempTalukaList,

                openVarientDropdown: false,
            })
        }
        else if (name === 'tempStateValue') {

            this.setState({
                ...this.state,
                tempStateValue: event.target.value,
                tempDistrictValue: event.target.value !== this.state.tempStateValue ? -1 : this.state.tempDistrictValue,
                tempTalukaValue: event.target.value !== this.state.tempStateValue ? -1 : this.state.tempTalukaValue,

                tempDistrictList: event.target.value !== this.state.tempStateValue ? [] : this.state.tempDistrictList,
                tempTalukaList: event.target.value !== this.state.tempStateValue ? [] : this.state.tempTalukaList,

                openStateDropdown: false,
            })
        }
        else if (name === 'tempDistrictValue') {

            this.setState({
                ...this.state,
                tempDistrictValue: event.target.value,
                tempTalukaValue: event.target.value !== this.state.tempDistrictValue ? -1 : this.state.tempTalukaValue,

                tempTalukaList: event.target.value !== this.state.tempDistrictValue ? [] : this.state.tempTalukaList,

                openDistrictDropdown: false,
            })
        }
        else if (name === 'tempTalukaValue') {

            this.setState({
                ...this.state,
                tempTalukaValue: event.target.value,
                // prodList: event.target.value !== this.state.tempTalukaValue ? [] : this.state.prodList,
                openTalukaDropdown: false,
            })
        }
    }

    applyFilterAndCloseDialog = () => {

        const { lid, tempProdList, tempStateList, tempDistrictList, tempTalukaList,
            tempProdValue, tempStateValue, tempDistrictValue, tempTalukaValue } = this.state

        let showError = false

        let finalLid = lid

        if (tempTalukaList && tempTalukaList.length > 0 && tempTalukaValue !== -1) {

            finalLid = tempTalukaList[tempTalukaValue].lid
        }
        else if (tempDistrictList && tempDistrictList.length > 0 && tempDistrictValue !== -1) {

            finalLid = tempDistrictList[tempDistrictValue].lid
        } else if (tempStateList && tempStateList.length > 0 && tempStateValue !== -1) {

            finalLid = tempStateList[tempStateValue].lid
        } else {
            showError = true
        }

        Log.verbose('applyFilterAndCloseDialog', 'showError : ' + showError)

        if (!showError) {
            this.setState({

                prodList: tempProdList,
                stateList: tempStateList,
                districtList: tempDistrictList,
                talukaList: tempTalukaList,

                prodValue: tempProdValue,
                stateValue: tempStateValue,
                districtValue: tempDistrictValue,
                talukaValue: tempTalukaValue,

                lid: finalLid,
                showSelectStateError: false,

                postList: [],
                fetchCount: this.state.fetchCount === 0 ? -1 : 0,
                loadMore: true
            })
            window.history.back();
        }
        else {

            this.setState({
                showSelectStateError: true
            })
        }
    }


    render() {

        // const classes = useStyles()
        const { loading, postList, loadMore, fetchCount, sortBy, sortByOrder,
            tempProdList, tempStateList, tempDistrictList, tempTalukaList, tempProdValue, tempStateValue, tempDistrictValue, tempTalukaValue,
            openVarientDropdown, openStateDropdown, openDistrictDropdown, openTalukaDropdown, showSelectStateError } = this.state

        const { pt } = this.props

        const { lang } = this.context

        const loader = <MyCircularProgress />

        let showApplyFilter = false;

        let state = this.props.location.state;

        if (state && 'showApplyFilter' in state) {
            showApplyFilter = state.showApplyFilter;
        }

        Log.verbose(this.state)

        return (

            <React.Fragment>

                <Paper style={{
                    marginTop: '-10px', marginLeft: '-10px', display: 'flex', zIndex: '1', flexDirection: 'row', width: 'inherit', maxWidth: 'inherit', flexWrap: 'nowrap',
                    alignItems: 'center', position: 'fixed', justifyItems: 'center'
                }}>
                    <div style={{
                        flex: '1', paddingTop: 8, paddingRight: 4, paddingBottom: 8, paddingLeft: 8,
                        display: 'flex', flexDirection: 'row', justifyContent: 'center', borderRight: '2px solid #e2e2e2'
                    }} onClick={this.handleRecent}>

                        {sortBy === apiConst.sbDate && order(sortByOrder)}
                        <Typography align="center" >{string[lang].str_button_recent}</Typography>
                    </div>

                    <div style={{
                        flex: '1', paddingTop: 8, paddingRight: 4, paddingBottom: 8, paddingLeft: 4,
                        display: 'flex', flexDirection: 'row', justifyContent: 'center', borderRight: '2px solid #e2e2e2'
                    }} onClick={this.handlePrice}>

                        {sortBy === apiConst.sbPrice && order(sortByOrder)}
                        <Typography align="center" >{string[lang].str_button_price}</Typography>

                    </div>
                    <div style={{
                        flex: '1', paddingTop: 8, paddingRight: 8, paddingBottom: 8, paddingLeft: 4,
                        display: 'flex', flexDirection: 'row', justifyContent: 'center'
                    }} onClick={this.handleRating}>

                        {sortBy === apiConst.sbRating && order(sortByOrder)}
                        <Typography align="center" >{string[lang].str_button_ratings}</Typography>
                    </div>
                </Paper>



                <InfiniteScrollExtend
                    pageStart={fetchCount}
                    loadMore={this.makeRequest}
                    hasMore={loadMore}
                    loader={loader}
                    threshold={300}>

                    <GridList cols={1} spacing={10} cellHeight='auto' style={{ marginTop: 40, marginBottom: 30 }}>
                        {postList.map((post, i) => {

                            return < GridListTile key={post.id} cols={1} >
                                <PostCard post={post} {...this.props} i={i} context={this.context} />
                            </GridListTile>

                        }

                        )}
                    </GridList>
                </InfiniteScrollExtend>

                <Container maxWidth="xs" style={{ padding: '0px', margin: '0px 0px 0px -10px' }} >
                    <Button variant="contained" color="secondary" onClick={this.openApplyFilter}
                        style={{ position: 'fixed', maxWidth: 'inherit', bottom: '0px', width: '-webkit-fill-available' }}>{string[lang].filter}</Button>
                </Container>

                {showApplyFilter && <Dialog fullScreen open={showApplyFilter} onClose={this.closeApplyFilter}>

                    <Container maxWidth="xs" style={{ backgroundColor: '#e2e2e2', padding: '0px', minHeight: '100vh' }}>
                        <ToolBar />

                        <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                            {/* <Typography align='center' style={{ marginBottom: '5px', flex: '1', color: mColor.emphasis_high }}>Select Location</Typography> */}

                            <FormControl variant='outlined' margin='dense' style={{ flex: '1', display: 'flex', marginBottom: '10px' }}>

                                <Select
                                    open={openVarientDropdown}
                                    value={tempProdValue}
                                    onOpen={this.handleVarientSelection}
                                    onClose={this.closeVarientSelection}
                                    onChange={this.handleChange('tempProdValue')}
                                    input={
                                        <OutlinedInput name="age" id="outlined-age-native-simple" />
                                    }
                                >
                                    <option style={{ minWidth: '35vh', padding: '2px 10px' }} disabled value={-1}>{string[lang].Select_Variant}</option>

                                    {tempProdList.map((prod, i) => {

                                        return <option style={{ padding: '2px 15px' }} key={prod.pid} value={i}>{lang === langCode.eng ? prod.pename : prod.plname}</option>
                                    })}


                                </Select  >
                            </FormControl>

                            <FormControl variant='outlined' margin='dense' style={{ flex: '1', display: 'flex', marginBottom: '10px' }}>

                                <Select
                                    open={openStateDropdown}
                                    value={tempStateValue}
                                    onOpen={this.handleStateSelection}
                                    onClose={this.closeStateSelection}
                                    onChange={this.handleChange('tempStateValue')}
                                    input={
                                        <OutlinedInput name="age" id="outlined-age-native-simple" />
                                    }
                                >
                                    <option style={{ minWidth: '35vh', padding: '2px 10px' }} disabled value={-1}>{string[lang].select_State}</option>

                                    {tempStateList.map((state, i) => {

                                        if ((pt === apiConst.ptBuy && state.bpqty > 0)
                                            || (pt === apiConst.ptSell && state.spqty > 0)

                                            || (pt === apiConst.ptGiveOnRent && state.rpqty > 0)

                                            || (pt === apiConst.ptGetOnRent && state.nrqty > 0)) {
                                            return <option style={{ padding: '2px 15px' }} key={state.lid} value={i}>
                                                {lang === langCode.eng ? state.lename : state.llname}
                                                {pt === apiConst.ptBuy && '   (' + state.bpqty + ')'}
                                                {pt === apiConst.ptSell && '   (' + state.spqty + ')'}
                                                {pt === apiConst.ptGiveOnRent && '   (' + state.rpqty + ')'}
                                                {pt === apiConst.ptGetOnRent && '   (' + state.nrqty + ')'}
                                            </option>

                                        }

                                        // return <div style={{
                                        //     display: 'flex', flexDirection: 'row', padding: '2px 10px'
                                        // }} key={state.lid} value={i} >

                                        //     <Typography style={{ flex:'0.8' }} align='left'>{state.lename}</Typography>
                                        //     <Typography style={{ flex:'0.2' }}align="right" color='secondary'>

                                        //         {pt === apiConst.ptBuy && state.bpqty > 0 && state.bpqty}

                                        //         {pt === apiConst.ptSell && state.spqty > 0 && state.spqty}

                                        //         {pt === apiConst.ptGiveOnRent && state.rpqty > 0 && state.rpqty}

                                        //         {pt === apiConst.ptGetOnRent && state.nrqty > 0 && state.nrqty}

                                        //     </Typography>

                                        // </div>

                                    })}


                                </Select  >
                            </FormControl>

                            {showSelectStateError && <Typography style={{
                                flexGrow: '1', color: mColor.error, fontFamily: 'regular', fontSize: '0.7rem', fontWeight: '200',
                            }}>{string[lang].please_select_a_state}</Typography>}

                            <FormControl disabled={!(tempStateList && tempStateList.length > 0 && tempStateValue !== -1)} variant='outlined' margin='dense' style={{ flex: '1', display: 'flex', marginBottom: '10px' }}>

                                <Select
                                    open={openDistrictDropdown}
                                    value={tempDistrictValue}
                                    onOpen={this.handleDistrictSelection}
                                    onClose={this.closeDistrictSelection}
                                    onChange={this.handleChange('tempDistrictValue')}
                                    input={
                                        <OutlinedInput name="age" id="outlined-age-native-simple" />
                                    }
                                >
                                    <option style={{ minWidth: '35vh', padding: '2px 10px' }} disabled value={-1}>{string[lang].select_District}</option>

                                    {tempDistrictList.map((district, i) => {

                                        if ((pt === apiConst.ptBuy && district.bpqty > 0)
                                            || (pt === apiConst.ptSell && district.spqty > 0)

                                            || (pt === apiConst.ptGiveOnRent && district.rpqty > 0)

                                            || (pt === apiConst.ptGetOnRent && district.nrqty > 0)) {
                                            return <option style={{ padding: '2px 15px' }} key={district.lid} value={i}>
                                                {lang === langCode.eng ? district.lename : district.llname}
                                                {pt === apiConst.ptBuy && '   (' + district.bpqty + ')'}
                                                {pt === apiConst.ptSell && '   (' + district.spqty + ')'}
                                                {pt === apiConst.ptGiveOnRent && '   (' + district.rpqty + ')'}
                                                {pt === apiConst.ptGetOnRent && '   (' + district.nrqty + ')'}
                                            </option>
                                        }
                                    })}


                                </Select>
                            </FormControl>

                            <FormControl disabled={!(tempDistrictList && tempDistrictList.length > 0 && tempDistrictValue !== -1)} variant='outlined' margin='dense' style={{ flex: '1', display: 'flex', marginBottom: '5px' }}>

                                <Select
                                    open={openTalukaDropdown}
                                    value={tempTalukaValue}
                                    onOpen={this.handleTalukaSelection}
                                    onClose={this.closeTalukaSelection}
                                    onChange={this.handleChange('tempTalukaValue')}
                                    input={
                                        <OutlinedInput name="age" id="outlined-age-native-simple" />
                                    }
                                >
                                    <option style={{ minWidth: '35vh', padding: '2px 10px' }} disabled value={-1}>{string[lang].select_Taluka}</option>

                                    {tempTalukaList.map((taluka, i) => {

                                        if ((pt === apiConst.ptBuy && taluka.bpqty > 0)
                                            || (pt === apiConst.ptSell && taluka.spqty > 0)

                                            || (pt === apiConst.ptGiveOnRent && taluka.rpqty > 0)

                                            || (pt === apiConst.ptGetOnRent && taluka.nrqty > 0)) {
                                            return <option style={{ padding: '2px 15px' }} key={taluka.lid} value={i}>
                                                {lang === langCode.eng ? taluka.lename : taluka.llname}
                                                {pt === apiConst.ptBuy && '   (' + taluka.bpqty + ')'}
                                                {pt === apiConst.ptSell && '   (' + taluka.spqty + ')'}
                                                {pt === apiConst.ptGiveOnRent && '   (' + taluka.rpqty + ')'}
                                                {pt === apiConst.ptGetOnRent && '   (' + taluka.nrqty + ')'}
                                            </option>
                                        }
                                    })}


                                </Select>
                            </FormControl>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>


                            <Button style={{ flex: '1', margin: '0px 10px' }} variant="contained" color="secondary"
                                onClick={this.applyFilterAndCloseDialog}>{string[lang].get_search_results}</Button>

                        </div>


                        {loading && <MyCircularProgress fullScreen />}

                    </Container>
                </Dialog>
                }

            </React.Fragment>

        )
    }
}

function order(asc) {

    if (asc === apiConst.sbOrderAsc) {
        return <FontIcon style={{ color: mColor.myasl_orange, fontSize: '0.8rem', marginRight: '4px', alignSelf: 'center' }} fontIcon={fontIcon.icon_down_arrow_2} />
    }
    else {
        return <FontIcon style={{ color: mColor.myasl_orange, fontSize: '0.8rem', marginRight: '4px', alignSelf: 'center' }} fontIcon={fontIcon.icon_up_arrow_2} />
    }
}

class PostCard extends React.Component {

    render() {

        //         "id":"1559133457670",
        // "recdate":"2019-05-29 18:07:37.0",
        // "price":"60",
        // "qty":"2",
        // "uom":"Kg",
        // "uom2":"Kg",
        // "rating":"3.0",
        // "haspic":"false",
        // "lid":"253302014",
        // "pid":"110299",
        // "isorganic":"true",
        // "isrfree":"false",
        // "isfpo":"false",
        // "locename":"Thane | Ambarnath | Chamtoli",
        // "loclname":"ठाणे | अंबरनाथ | चमटोली",
        // "prod":"मराठीOther Acai Berry",
        // "prod2":"Other Acai Berry",
        // "username":"नवनाथ",
        // "lastname":"काटकर",
        // "uid":"1559116050980"




        const { pt, i, context } = this.props

        const { lang } = context
        const avatarColor = userAvatarColors[i % userAvatarColors.length]

        let { id, recdate, price, qty, uom, uom2, priceuom, qtyuom, rating, haspic, lid,
            pid, isorganic, isrfree, isfpo, locename, loclname, prod, prod2, username, lastname, uid } = this.props.post

        isorganic = isorganic === 'true'
        isrfree = isrfree === 'true'
        isfpo = isfpo === 'true'

        const date = new Date(recdate)
        const postDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()

        Log.verbose('ddd', pt)

        return (

            <NavLink to={pathConst.postDetail(pt, id)} style={{ textDecoration: 'none' }}>
                <Paper style={{
                    display: 'flex', paddingTop: '10px', paddingRight: '10px', paddingBottom: '10px', paddingLeft: '0px',
                    flexDirection: 'row', alignItems: 'center'
                }}>

                    {(pt === apiConst.ptSell || pt === apiConst.ptGiveOnRent) &&

                        <ImageWithFallbacks style={{ width: '25%', height: '25%', border: '1px solid #e2e2e2', margin: '0px 10px' }}
                            src={apiUrl.postDetailImage(haspic, id, pid, pt)} fallbacks={[apiUrl.prodImages(pid), defaultImg]} />
                    }
                    {(pt === apiConst.ptBuy || pt === apiConst.ptGetOnRent) &&

                        <ImageWithComponentFallback component={<FontIcon style={{ color: avatarColor, fontSize: '6rem' }} fontIcon={fontIcon.icon_user_profile_in_circle_solid} />}
                            src={apiUrl.userImage(uid)}
                            style={{ width: '25%', height: '25%', border: '0px solid #e2e2e2', margin: '0px 10px', borderRadius: '100px' }}
                        />

                    }

                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ flexGrow: '1', color: mColor.emphasis_high, fontFamily: 'bold', fontSize: '0.9rem', fontWeight: '600' }}>{username + ' ' + lastname}</Typography>
                            <Typography style={{ color: mColor.emphasis_medium, fontSize: '0.7rem', fontFamily: 'regular' }}>{postDate}</Typography>
                        </div>
                        <Typography style={{ color: mColor.emphasis_medium, fontFamily: 'regular', fontSize: '0.9rem', fontWeight: '500' }}>{lang === langCode.eng ? locename : loclname}</Typography>

                        <div>
                            <StarRatingComponent
                                name="rate1"
                                starCount={5}
                                value={parseInt(rating)}
                                editing={false}
                            />
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ flexGrow: '1', color: mColor.emphasis_high, fontFamily: 'bold', fontSize: '0.9rem', fontWeight: '600' }}>{lang === langCode.eng ? prod2 : prod}</Typography>

                            {isorganic && <Typography style={{ borderRadius: '2px', alignSelf: 'center', padding: '1px 5px', backgroundColor: mColor.organic, color: mColor.white, fontFamily: 'bold', fontSize: '0.7rem', fontWeight: '600' }}>{'Organic'}</Typography>}
                            {isrfree && <Typography style={{ borderRadius: '2px', alignSelf: 'center', padding: '1px 5px', marginLeft: '5px', backgroundColor: mColor.rfree, color: mColor.white, fontFamily: 'bold', fontSize: '0.7rem', fontWeight: '600' }}>{'RFree'}</Typography>}
                            {isfpo && <Typography style={{ borderRadius: '2px', alignSelf: 'center', padding: '1px 5px', marginLeft: '5px', backgroundColor: mColor.fpo, color: mColor.white, fontFamily: 'bold', fontSize: '0.7rem', fontWeight: '600' }}>{'FPO'}</Typography>}

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ flexGrow: '1', color: mColor.accent, fontFamily: 'regular', fontSize: '0.9rem', fontWeight: '500' }}>{string[lang].quantity_colon + ' ' + qty + ' ' + (string[lang].uom[qtyuom] ? string[lang].uom[qtyuom] : uom)}</Typography>
                            <Typography style={{ color: mColor.accent, fontFamily: 'regular', fontSize: '0.9rem', fontWeight: '500' }}>{string[langCode.eng].rupee_symbol + ' ' + price + '/' + (string[lang].uom[priceuom] ? string[lang].uom[priceuom] : uom2)}</Typography>
                        </div>
                    </div>
                </Paper >
            </NavLink>
        );
    }

}

