export const fontIcon = {
    icon_post_add: '&#xe0d4;',
    icon_change_language: "8",
    icon_share_app: "C",
    icon_forward: "&#xe004;",
    icon_share: "&#xe0a4;",
    icon_share_solid: "&#xe0ca;",
    icon_star_rounded: "&#xe0a5;",
    icon_star_rounded_solid: "&#xe0a6;",
    icon_star: "&#xe00f;",
    icon_star_solid: "&#xe012;",
    icon_padlock: "&#xe050;",
    icon_switch_user: "4",
    icon_search: "&#xe0aa;",
    icon_my_post: "&#xe04b;",
    icon_qna: "&#xe0ac;",
    icon_ad_with_us: "&#xe046;",
    icon_govt: "&#xe0bc;",
    icon_bell: "<![CDATA[<]]>",
    icon_hamburger: "&#xe005;",
    icon_right_arrow: "h",
    icon_right_arrow_half: "v",
    icon_down_arrow_half: "t",
    icon_left_arrow: "f",
    icon_left_arrow_half: "u",
    icon_up_arrow_half: "w",
    icon_up_arrow: "i",
    icon_down_arrow: "g",
    icon_camera: "m",
    icon_pencil: "&#xe0c9;",
    icon_pencil_edit: "^",
    icon_man_in_formal: "&#xe0cb;",
    icon_calendar: "&#xe058;",
    icon_mobile: "&#xe0d0;",
    icon_phone: "J",
    icon_cross_in_circle: "x",
    icon_cross_solid_in_circle: "s",
    icon_farmer: "&#xe0d9;",
    icon_news: "&#xe0b6;",
    icon_shop: "&#xe055;",
    icon_laptop_case: "&#xe0b8;",
    icon_qualification: "&#xe0ce;",
    icon_location: "&#xe0cd;",
    icon_leaves: "&#xe0b9;",
    icon_play_video: "&#xe0ba;",
    icon_7_12: "&#xe095;",
    icon_user_profile: "\\",
    icon_user_profile_in_circle: "p",
    icon_user_profile_in_circle_solid: "c",
    icon_plus: "&#xe0d1;",

    icon_like: "&#xe051;",
    icon_delete: "&#xe05c;",
    icon_comment: "&#xe04c;",
    icon_views: "&#xe049;",
    icon_buy: "&#xe09a;",
    icon_sell: "&#xe099;",
    icon_buy_1: "&#xe0d7;",
    icon_sell_1: "&#xe0e3;",
    icon_get_on_rent: "&#xe0d6;",
    icon_give_on_rent: "&#xe0e2;",
    icon_youtube_play: ".",
    icon_post_play: "&#xe043;",
    icon_three_dot: "G",

    icon_weather_clear_day: "&#xe082;",
    icon_weather_clear_night: "&#xe076;",
    icon_weather_rain: "&#xe07a;",
    icon_weather_snow: "&#xe07e;",
    icon_weather_sleet: "&#xe07f;",
    icon_weather_wind: "&#xe087;",
    icon_weather_fog: "&#xe06c;",
    icon_weather_cloudy: "&#xe05f;",
    icon_weather_partly_cloudy_day: "&#xe065;",
    icon_weather_partly_cloudy_night: "&#xe063;",
    icon_weather_hail: "&#xe06e;",
    icon_weather_thunderstorm: "&#xe073;",

    icon_rupee: "/",
    icon_play_round: "L",
    icon_down_arrow_2: '&#xe0c7;',
    icon_up_arrow_2: '&#xe0c6;',
    icon_tnc: '&#xe0ad;',
    icon_phone_2: '&#xe0d2;'
}