import React from 'react'
import { makeStyles, FormControl, InputLabel, Select, OutlinedInput, NativeSelect, FormHelperText, Typography, InputBase, TextField, Checkbox, FormControlLabel, Button, Dialog, Divider, Container, MenuItem, withStyles, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'
import { apiUrl, apiInterface } from '../network';
import { color, string, fontIcon } from '../res';
import { dataSource, langCode } from '../constants';
import { ToolBar, FontIcon, ImageWithFallbacks, MyCircularProgress } from '../components';
import defaultImg from '../res/img/m_default_gray_leaf.jpg'
import '../App.css'
import { UserContext } from '../context';
import { LocationUtils, Log } from '../utils';


const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(1),
    },
}));



export default class AddPost extends React.Component {

    static contextType = UserContext
    constructor(props, context) {
        super(props, context)


        const loc = LocationUtils.getLocDetails(context.lid, context.lename, context.llname)

        this.state = {
            prodList: [],
            loading: false,
            selectedProd: -1,
            quantity: '',
            price: '',
            unitOfQuantity: -1,
            unitOfPrice: -1,
            units: dataSource.unit_of_cat(props.pid.substring(0, 2), context.lang),
            isOrganic: false,
            isRFree: false,
            isFpo: false,
            desc: '',
            validPost: false,

            stateList: loc && loc.state ? [loc.state] : [],
            districtList: loc && loc.district ? [loc.district] : [],
            talukaList: loc && loc.taluka ? [loc.taluka] : [],

            tempStateList: loc && loc.state ? [loc.state] : [],
            tempDistrictList: loc && loc.district ? [loc.district] : [],
            tempTalukaList: loc && loc.taluka ? [loc.taluka] : [],

            stateValue: loc && loc.state ? 0 : -1,
            districtValue: loc && loc.district ? 0 : -1,
            talukaValue: loc && loc.taluka ? 0 : -1,

            tempStateValue: loc && loc.state ? 0 : -1,
            tempDistrictValue: loc && loc.district ? 0 : -1,
            tempTalukaValue: loc && loc.taluka ? 0 : -1,

            postLocation: { lid: context.lid, lefullname: context.lename, llfullname: context.llname },

            openStateDropdown: false,
            openDistrictDropdown: false,
            openTalukaDropdown: false,

            showPostSuccessfulDialog: false,
            showVariantErr: false,
            showQuantityErr: false,
            showQuantityUnitErr: false,
            showPriceErr: false,
            showPriceUnitErr: false,
            showSelectDistrictError: false

        }
    }

    componentDidMount() {
        this.makeVarientProductListRequest()
    }

    makeVarientProductListRequest = () => {

        const pid = this.props.pid
        const payLoad = { pinnumber: '-1', uid: '-1' }

        if (pid) {
            payLoad.pid = pid
        }

        apiInterface.post(apiUrl.getProd, payLoad)
            .then((res) => {

                this.setState({
                    prodList: res.data.data,
                })

            })
            .catch((err) => {
            })
    }

    makeLocationRequest = (lid) => {

        this.setState({
            loading: true
        })

        const payLoad = { pinnumber: '-1', uid: '-1' }

        if (lid) {
            payLoad.lid = lid
        }

        apiInterface.post(apiUrl.getLoc, payLoad)
            .then((res) => {

                Log.verbose('sss', lid)
                if (!lid || lid.trim().length === 0) {
                    this.setState({
                        loading: false,
                        tempStateList: res.data.data,
                        tempDistrictList: [],
                        tempTalukaList: [],
                        tempDistrictValue: -1,
                        tempTalukaValue: -1,
                        openStateDropdown: true
                    })
                }
                else if (lid.substring(2, 4) === '00') {
                    this.setState({
                        loading: false,
                        tempDistrictList: res.data.data,
                        tempTalukaList: [],
                        tempTalukaValue: -1,
                        openDistrictDropdown: true
                    })
                }
                else if (lid.substring(4, 6) === '00') {
                    this.setState({
                        loading: false,
                        tempTalukaList: res.data.data,
                        openTalukaDropdown: true
                    })
                }

            })
            .catch((err) => {
            })
    }

    makeSubmitPostRequest = () => {

        this.setState({
            loading: true
        })

        const { prodList, selectedProd, quantity, price, unitOfQuantity, unitOfPrice, units,
            isOrganic, isRFree, isFpo, desc, postLocation } = this.state

        let pid = selectedProd !== -1 && prodList && prodList.length > 0 ? prodList[selectedProd].pid : this.props.pid

        const payload = {
            "googlemsg": "",
            "pid": pid,
            "posttype": this.props.pt,
            "pinnumber": this.context.pinnumber,
            "uid": this.context.uid,
            "lid": postLocation.lid,
            "price": price,
            "qty": quantity,
            "uom": string[langCode.eng].uom[units[unitOfPrice].id],
            "uom2": string[langCode.eng].uom[units[unitOfQuantity].id],
            "priceuom": units[unitOfPrice].id + '',
            "qtyuom": units[unitOfQuantity].id + '',
            "comment": desc,
            "fullNotifMsg": "",
            // "fullNotifMsg":"notifconst_v44title=Chetan Patil Wants to Buy Fruits (Acai Berry).###Chetan Patil यांना फळे (करवन्‌द) विकत घेणे आहे.&&&subTitle=Location: Jalgaon | Bhusawal | Bhusawal City\nQuantity: 1000 Kg\nRate: ₹ 80\/Kg###स्थान: जळगाव | भुसावळ | भुसावळ शहर\nप्रमाण: 1000 Kg\nदर: ₹ 80\/Kg&&&assetId=m_110202&&&profId=1548224993124&&&dl=http:\/\/marketyard.org\/index.php?st=PD&id=@postid@&type=1&pid=110202&pname=",
            "isorganic": isOrganic,
            "isrfree": isRFree,
            "isfpo": isFpo
        }

        apiInterface.post(apiUrl.submitPost, payload)
            .then((res) => {

                this.setState({
                    loading: false,
                    showPostSuccessfulDialog: true
                })


            })
            .catch((err) => {
            })

    }

    // handleChange = (name) => event => {

    //     Log.verbose('sss', 'handleChange ' + name)

    //     let closeDropdownName = ''

    //     if (name === 'tempStateValue') {
    //         closeDropdownName = 'openStateDropdown'
    //     }
    //     else if (name === 'tempDistrictValue') {
    //         closeDropdownName = 'openDistrictDropdown'
    //     }
    //     else if (name === 'tempTalukaValue') {
    //         closeDropdownName = 'openTalukaDropdown'
    //     }

    //     this.setState({
    //         ...this.state,
    //         // tempDistrictValue: name === 'tempStateValue' ? -1 : this.state.tempDistrictValue,
    //         // tempTalukaValue: name === 'tempDistrictValue' ? -1 : this.state.tempTalukaValue,
    //         [name]: event.target.value,
    //         [closeDropdownName]: false,
    //     })

    // }

    handleChange = (name) => event => {

        Log.verbose('sss', 'handleChange ' + name)


        if (name === 'tempStateValue') {

            this.setState({
                ...this.state,
                tempStateValue: event.target.value,
                tempDistrictValue: event.target.value !== this.state.tempStateValue ? -1 : this.state.tempDistrictValue,
                tempTalukaValue: event.target.value !== this.state.tempStateValue ? -1 : this.state.tempTalukaValue,

                tempDistrictList: event.target.value !== this.state.tempStateValue ? [] : this.state.tempDistrictList,
                tempTalukaList: event.target.value !== this.state.tempStateValue ? [] : this.state.tempTalukaList,

                openStateDropdown: false,
            })
        }
        else if (name === 'tempDistrictValue') {

            this.setState({
                ...this.state,
                tempDistrictValue: event.target.value,
                tempTalukaValue: event.target.value !== this.state.tempDistrictValue ? -1 : this.state.tempTalukaValue,

                tempTalukaList: event.target.value !== this.state.tempDistrictValue ? [] : this.state.tempTalukaList,

                openDistrictDropdown: false,
            })
        }
        else if (name === 'tempTalukaValue') {

            this.setState({
                ...this.state,
                tempTalukaValue: event.target.value,

                openTalukaDropdown: false,
            })
        }
        else {
            this.setState({
                ...this.state,
                [name]: event.target.value,
            })
        }
    }

    handleNumberInput = (name) => event => {

        let a = 0

        if (name === 'quantity') {
            if (!event.target.value.length > 5) {
                a = event.target.validity.valid ? event.target.value : this.state[name]
            }
            else {
                a = event.target.value.substring(0, 5)
            }
        }

        if (name === 'price') {
            if (!event.target.value.length > 8) {
                a = event.target.validity.valid ? event.target.value : this.state[name]
            }
            else {
                a = event.target.value.substring(0, 8)
            }
        }

        this.setState({
            [name]: a,
        })

    }

    handleCbChange = (name) => event => {

        let iso = this.state.isOrganic
        let isr = this.state.isRFree
        let isf = this.state.isFpo

        if (event.target.checked) {

            if (name === 'isOrganic') {
                iso = true
                isr = false
            }
            else if (name === 'isRFree') {
                iso = false
                isr = true
            }
            else if (name === 'isFpo') {
                isf = true
            }
        }
        else {
            if (name === 'isOrganic') {
                iso = false
            }
            else if (name === 'isRFree') {
                isr = false
            }
            else if (name === 'isFpo') {
                isf = false
            }
        }

        this.setState({
            isOrganic: iso,
            isRFree: isr,
            isFpo: isf
        })
    }

    handleNext = () => {
        const { selectedProd, quantity, price, unitOfQuantity, unitOfPrice } = this.state

        if (selectedProd !== -1 && quantity !== '' && unitOfQuantity !== -1 &&
            price !== '' && unitOfPrice !== -1) {

            this.setState({
                validPost: true,
                showVariantErr: selectedProd === -1,
                showQuantityErr: quantity === '',
                showQuantityUnitErr: unitOfQuantity === -1,
                showPriceErr: price === '',
                showPriceUnitErr: unitOfPrice === -1
            }, () => {
                this.props.history.push(this.props.location.pathname + this.props.location.search, { showPreview: true });
            })


        }
        else {
            this.setState({
                validPost: false,
                showVariantErr: selectedProd === -1,
                showQuantityErr: quantity === '',
                showQuantityUnitErr: unitOfQuantity === -1,
                showPriceErr: price === '',
                showPriceUnitErr: unitOfPrice === -1

            })
        }

    }

    closePreview = () => {
        window.history.back();

    }

    setLocationAndcloseLocationDialog = () => {

        Log.verbose('sss', 'setLocationAndcloseLocationDialog')

        const { tempStateList, tempDistrictList, tempTalukaList, tempStateValue, tempDistrictValue, tempTalukaValue, postLocation } = this.state

        let showError = false

        let finalLocation = postLocation

        if (tempTalukaList && tempTalukaList.length > 0 && tempTalukaValue !== -1) {

            finalLocation = tempTalukaList[tempTalukaValue]
        }
        else if (tempDistrictList && tempDistrictList.length > 0 && tempDistrictValue !== -1) {

            finalLocation = tempDistrictList[tempDistrictValue]
        } else if (tempStateList && tempStateList.length > 0 && tempStateValue !== -1) {

            showError = true
            finalLocation = tempStateList[tempStateValue]
        }

        if (!showError) {
            this.setState({

                stateList: tempStateList,
                districtList: tempDistrictList,
                talukaList: tempTalukaList,
                stateValue: tempStateValue,
                districtValue: tempDistrictValue,
                talukaValue: tempTalukaValue,
                postLocation: finalLocation,
                showSelectDistrictError: false
            })
            window.history.back();
        }
        else {

            this.setState({
                showSelectDistrictError: true
            })
        }




    }

    handlePostLocationDialog = () => {

        this.setState({

            tempStateList: this.state.stateList,
            tempDistrictList: this.state.districtList,
            tempTalukaList: this.state.talukaList,

            tempStateValue: this.state.stateValue,
            tempDistrictValue: this.state.districtValue,
            tempTalukaValue: this.state.talukaValue,

        })
        this.props.history.push(this.props.location.pathname + this.props.location.search, { ...this.props.location.state, showLocationDialog: true });

    }

    closeLocationDialog = () => {
        window.history.back();

    }

    handleStateSelection = () => {

        Log.verbose('sss', 'handleStateSelection')
        const { tempStateList } = this.state
        if (tempStateList && tempStateList.length > 1) {
            this.setState({
                openStateDropdown: true
            })

        }
        else {
            this.makeLocationRequest(null)
        }
    }


    handleDistrictSelection = () => {
        Log.verbose('sss', 'handleDistrictSelection')
        const { tempStateList, tempDistrictList, tempStateValue } = this.state
        if (tempDistrictList && tempDistrictList.length > 1) {
            this.setState({
                openDistrictDropdown: true
            })

        }
        else {
            this.makeLocationRequest(tempStateList[tempStateValue].lid)
        }
    }

    handleTalukaSelection = () => {
        Log.verbose('sss', 'handleTalukaSelection')
        const { tempDistrictList, tempTalukaList, tempDistrictValue } = this.state
        if (tempTalukaList && tempTalukaList.length > 1) {
            this.setState({
                openTalukaDropdown: true
            })

        }
        else {
            this.makeLocationRequest(tempDistrictList[tempDistrictValue].lid)
        }
    }


    closeStateSelection = () => {

        Log.verbose('sss', 'closeStateSelection')
        this.setState({
            openStateDropdown: false
        })
    }

    closeDistrictSelection = () => {
        Log.verbose('sss', 'closeDistrictSelection')
        this.setState({
            openDistrictDropdown: false
        })
    }

    closeTalukaSelection = () => {
        Log.verbose('sss', 'closeTalukaSelection')
        this.setState({
            openTalukaDropdown: false
        })
    }

    closePostSuccessfulDialog = () => {
        this.props.history.go(-(this.props.history.length - 1))
        Log.verbose('sss', 'closePostSuccessfulDialog')
    }

    render() {

        const { loading, prodList, selectedProd, quantity, price, unitOfQuantity, unitOfPrice, units,
            isOrganic, isRFree, isFpo, desc, validPost, postLocation,
            tempStateList, tempDistrictList, tempTalukaList, tempStateValue, tempDistrictValue, tempTalukaValue,
            openStateDropdown, openDistrictDropdown, openTalukaDropdown, showPostSuccessfulDialog,
            showVariantErr, showQuantityErr, showQuantityUnitErr, showPriceErr, showPriceUnitErr, showSelectDistrictError } = this.state

        const { pt, pid } = this.props
        const { lang } = this.context

        Log.verbose(this.context)
        Log.verbose(this.state)

        let showPreview = false;
        let showLocationDialog = false;

        let state = this.props.location.state;

        if (state && 'showPreview' in state) {
            showPreview = state.showPreview;
        }

        if (state && 'showLocationDialog' in state) {
            showLocationDialog = state.showLocationDialog;
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>

                <FormControl variant='outlined' fullWidth={true} margin='dense'>

                    <NativeSelect
                        value={selectedProd}
                        onChange={this.handleChange('selectedProd')}
                        input={
                            <OutlinedInput name="age" id="outlined-age-native-simple" />
                        }
                    >
                        <option value={-1}>{string[lang].Select_Variant}</option>

                        {prodList.map((prod, i) => {

                            return <option key={prod.pid} value={i}>{lang === langCode.eng ? prod.pename : prod.plname}</option>
                        })}


                    </NativeSelect>
                </FormControl>

                {/* <div style={{ flex: '0.3', height: '17px' }}>
                    {showVariantErr && <Typography style={{
                        flexGrow: '1', color: color.error, fontFamily: 'regular', fontSize: '0.7rem', fontWeight: '200',
                    }}>Please select Variant</Typography>}

                </div> */}


                <div style={{ display: 'flex', flexDirection: 'row' }}>

                    {showVariantErr && <Typography style={{
                        flexGrow: '1', color: color.error, fontFamily: 'regular', fontSize: '0.7rem', fontWeight: '200',
                    }}>{string[lang].Please_select_a_variant}</Typography>}

                    <div style={{ flex: '0.3', height: '17px' }}></div>

                </div>


                <Typography style={{
                    marginTop: '0px',
                    flexGrow: '1', flexDirection: 'column', color: color.emphasis_high, fontFamily: 'bold', fontSize: '0.9rem', fontWeight: '600',
                }}>{string[lang].product_quantity}</Typography>


                <div style={{ display: 'flex', flexDirection: 'row' }}>


                    <TextField
                        style={{ flex: '1' }}
                        id="outlined-dense"
                        value={quantity}
                        onChange={this.handleNumberInput('quantity')}
                        margin="dense"
                        variant="outlined"
                        type="number"
                        placeholder={string[lang].quantity}
                    />

                    <div style={{ flex: '0.3', display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'flex-end', paddingBottom: '18px' }}></div>

                    <FormControl variant='outlined' margin='dense' style={{ flex: '1', display: 'flex' }}>

                        <NativeSelect
                            value={unitOfQuantity}
                            onChange={this.handleChange('unitOfQuantity')}
                            input={
                                <OutlinedInput name="age" id="outlined-age-native-simple" />
                            }
                        >
                            <option disabled value={-1}  >{string[lang].spinner_prompt}</option>

                            {units.map((unit, i) => {

                                return <option key={unit.id} value={i}>{unit.name}</option>
                            })}

                        </NativeSelect>
                    </FormControl>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>

                    {showQuantityErr && <Typography style={{
                        flexGrow: '1', color: color.error, fontFamily: 'regular', fontSize: '0.7rem', fontWeight: '200',
                    }}>{string[lang].Please_fill_the_quantity}</Typography>}

                    <div style={{ flex: '0.3', height: '17px' }}></div>

                    {showQuantityUnitErr && <Typography style={{
                        flexGrow: '1', color: color.error, fontFamily: 'regular', fontSize: '0.7rem', fontWeight: '200',
                    }}>{string[lang].Please_select_a_unit}</Typography>}
                </div>

                <Typography style={{
                    flexGrow: '1', flexDirection: 'column', color: color.emphasis_high, fontFamily: 'bold', fontSize: '0.9rem', fontWeight: '600',
                }}>{string[lang].enter_expected_price}</Typography>


                <div style={{ display: 'flex', flexDirection: 'row' }}>


                    <TextField
                        style={{ flex: '1' }}
                        id="outlined-dense"
                        value={price}
                        onChange={this.handleNumberInput('price')}
                        margin="dense"
                        variant="outlined"
                        type="number"
                        placeholder={string[langCode.eng].rupee_symbol}
                    />

                    <div style={{ flex: '0.3', display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'flex-end', paddingBottom: '18px' }}>{string[lang].per}</div>

                    <FormControl variant='outlined' margin='dense' style={{ flex: '1', display: 'flex' }}>

                        <NativeSelect
                            value={unitOfPrice}
                            onChange={this.handleChange('unitOfPrice')}
                            input={
                                <OutlinedInput name="age" id="outlined-age-native-simple" />
                            }
                        >
                            <option disabled value={-1}>{string[lang].spinner_prompt}</option>

                            {units.map((unit, i) => {

                                return <option key={unit.id} value={i}>{unit.name}</option>
                            })}


                        </NativeSelect>
                    </FormControl>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>

                    {showPriceErr && <Typography style={{
                        flexGrow: '1', color: color.error, fontFamily: 'regular', fontSize: '0.7rem', fontWeight: '200',
                    }}>{string[lang].Please_fill_the_price}</Typography>}

                    <div style={{ flex: '0.3', height: '17px' }}></div>

                    {showPriceUnitErr && <Typography style={{
                        flexGrow: '1', color: color.error, fontFamily: 'regular', fontSize: '0.7rem', fontWeight: '200',
                    }}>{string[lang].Please_select_a_unit}</Typography>}
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                    <FormControlLabel control={<Checkbox checked={isOrganic} onChange={this.handleCbChange('isOrganic')} value="isorganic" />} label={string[lang].organic} />
                    <FormControlLabel control={<Checkbox checked={isRFree} onChange={this.handleCbChange('isRFree')} value="isrfree" />} label={string[lang].residue_free} />
                    <FormControlLabel control={<Checkbox checked={isFpo} onChange={this.handleCbChange('isFpo')} value="isfpo" />} label={string[lang].fpo} />
                </div>

                <TextField
                    style={{ flex: '1' }}
                    id="outlined-dense"
                    value={desc}
                    onChange={this.handleChange('desc')}
                    margin="dense"
                    variant="outlined"
                    placeholder={string[lang].str_add_post_product_description}
                    multiline={true}
                    rows='5'
                />

                <Container maxWidth="xs" style={{ padding: '0px', margin: '0px 0px 0px -10px' }} >
                    <Button variant="contained" color="secondary" onClick={this.handleNext}
                        style={{ position: 'absolute', maxWidth: 'inherit', bottom: '0px', width: '-webkit-fill-available' }}>{string[lang].next}</Button>
                </Container>

                {validPost && <Dialog fullScreen open={showPreview} onClose={this.closePreview}>

                    <Container maxWidth="xs" style={{ backgroundColor: '#e2e2e2', padding: '0px', minHeight: '100vh' }}>
                        <ToolBar />

                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                            <div style={{ padding: 10, display: 'flex', padding: '10px', flexDirection: 'row', alignItems: 'center' }}>

                                <ImageWithFallbacks style={{ width: '60%', height: '60%', border: '1px solid #e2e2e2', marginRight: '10px' }}
                                    src={apiUrl.postDetailImage(false, '', pid, pt)} fallbacks={[apiUrl.prodImages(pid), defaultImg]} />
                                {/* camera */}

                                {/* <input
        accept="image/*"
        style={{display:'none'}}
        id="outlined-button-file"
        type="file"
      />
      <label htmlFor="outlined-button-file">
      <ImageWithFallbacks style={{ width: '60%', height: '60%', border: '1px solid #e2e2e2', marginRight: '10px' }}
                                    src={apiUrl.postDetailImage(false, pid, pid, pt)} fallbacks={[apiUrl.prodImages(pid), defaultImg]} />
      </label> */}

                                <div style={{ display: 'flex', flexDirection: 'column' }}>

                                    <Typography style={{ flex: '1', color: color.emphasis_high }}>{string[lang].price_colon}</Typography>
                                    <Typography style={{ flex: '1', color: color.emphasis_high }}>{string[langCode.eng].rupee_symbol + ' ' + price + '/' + units[unitOfPrice].name}</Typography>
                                    <Divider />
                                    <Typography style={{ flex: '1', color: color.emphasis_high }}>{string[lang].quantity_colon}</Typography>
                                    <Typography style={{ flex: '1', color: color.emphasis_high }}>{quantity + ' ' + units[unitOfQuantity].name}</Typography>

                                </div>

                            </div>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', padding: '10px', backgroundColor: color.gray, margin: '0px 10px' }}>

                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
                                <Typography style={{ flex: '1', color: color.emphasis_high }}>{string[lang].post_at_location}</Typography>
                                <Typography style={{ flex: '1', color: color.emphasis_high }}>{lang === langCode.eng ? postLocation.lefullname : postLocation.llfullname}</Typography>
                            </div>
                            <FontIcon onClick={this.handlePostLocationDialog} style={{ color: color.emphasis_high, fontSize: '1rem', marginRight: '4px', alignSelf: 'center' }} fontIcon={fontIcon.icon_pencil} />
                        </div>

                        <Button variant="contained" color="secondary" onClick={this.makeSubmitPostRequest}
                            style={{ position: 'absolute', maxWidth: 'inherit', bottom: '0px', width: '-webkit-fill-available' }}>{string[lang].submit}</Button>

                        {loading && <MyCircularProgress fullScreen />}

                    </Container>
                </Dialog>
                }

                {validPost && showPreview && <Dialog open={showLocationDialog} onClose={this.closeLocationDialog} fullWidth={true} maxWidth={false}>

                    <div style={{ padding: '20px 10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography align='center' style={{ marginBottom: '5px', flex: '1', color: color.emphasis_high }}>{string[lang].select_location}</Typography>

                            <FormControl variant='outlined' margin='dense' style={{ flex: '1', display: 'flex', marginBottom: '10px' }}>

                                <Select
                                    style={{ minWidth: '230px' }}
                                    open={openStateDropdown}
                                    value={tempStateValue}
                                    onOpen={this.handleStateSelection}
                                    onClose={this.closeStateSelection}
                                    onChange={this.handleChange('tempStateValue')}
                                    input={
                                        <OutlinedInput name="age" id="outlined-age-native-simple" />
                                    }
                                >
                                    <option style={{ minWidth: '230px' }} disabled value={-1}>{string[lang].select_State}</option>

                                    {tempStateList.map((state, i) => {

                                        return <option style={{ minWidth: '230px' }} key={state.lid} value={i}>{lang === langCode.eng ? state.lename : state.llname}</option>
                                    })}


                                </Select  >
                            </FormControl>

                            <FormControl disabled={!(tempStateList && tempStateList.length > 0 && tempStateValue !== -1)} variant='outlined' margin='dense' style={{ flex: '1', display: 'flex', marginBottom: '10px' }}>

                                <Select
                                    style={{ minWidth: '230px' }}
                                    open={openDistrictDropdown}
                                    value={tempDistrictValue}
                                    onOpen={this.handleDistrictSelection}
                                    onClose={this.closeDistrictSelection}
                                    onChange={this.handleChange('tempDistrictValue')}
                                    input={
                                        <OutlinedInput name="age" id="outlined-age-native-simple" />
                                    }
                                >
                                    <option style={{ minWidth: '230px' }} disabled value={-1}>{string[lang].select_District}</option>

                                    {tempDistrictList.map((district, i) => {

                                        return <option style={{ minWidth: '230px' }} key={district.lid} value={i}>{lang === langCode.eng ? district.lename : district.llname}</option>
                                    })}


                                </Select>
                            </FormControl>

                            {showSelectDistrictError && <Typography style={{
                                flexGrow: '1', color: color.error, fontFamily: 'regular', fontSize: '0.7rem', fontWeight: '200',
                            }}>Please select a district</Typography>}

                            <FormControl disabled={!(tempDistrictList && tempDistrictList.length > 0 && tempDistrictValue !== -1)} variant='outlined' margin='dense' style={{ flex: '1', display: 'flex', marginBottom: '5px' }}>

                                <Select
                                    style={{ minWidth: '230px' }}
                                    open={openTalukaDropdown}
                                    value={tempTalukaValue}
                                    onOpen={this.handleTalukaSelection}
                                    onClose={this.closeTalukaSelection}
                                    onChange={this.handleChange('tempTalukaValue')}
                                    input={
                                        <OutlinedInput name="age" id="outlined-age-native-simple" />
                                    }
                                >
                                    <option style={{ minWidth: '230px' }} disabled value={-1}>{string[lang].select_Taluka}</option>

                                    {tempTalukaList.map((taluka, i) => {

                                        return <option style={{ minWidth: '230px' }} key={taluka.lid} value={i}>{lang === langCode.eng ? taluka.lename : taluka.llname}</option>
                                    })}


                                </Select>
                            </FormControl>

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>

                            <Button style={{ flex: '1' }} variant="outlined" color="secondary"
                                onClick={this.closeLocationDialog} >{string[lang].cancel}</Button>

                            <div style={{ flex: '0.2' }} ></div>

                            <Button style={{ flex: '1' }} variant="contained" color="secondary"
                                onClick={this.setLocationAndcloseLocationDialog}>{string[lang].ok}</Button>

                        </div>
                    </div>
                </Dialog>
                }

                <Dialog
                    open={showPostSuccessfulDialog}
                    onClose={this.closePostSuccessfulDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{string[lang].Post_successfully_added}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closePostSuccessfulDialog} color="secondary" autoFocus >{string[lang].ok}</Button>
                    </DialogActions>
                </Dialog>

            </div >)
    }
}