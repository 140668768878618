import { string, fontIcon, color } from '../res'
import { dlConst } from './dlConst'
import { apiConst } from './apiConst'
import { Log, IntentUtils } from '../utils';
import { pathConst } from './pathConst';


export const dataSource = {

    // lang: () => {

    //     return 'eng'
    // },

    home_post_option: (lang) => {

        // const lang = dataSource.lang()

        return [
            {
                title: string[lang].str_home_search_ad,
                icon: fontIcon.icon_search,
                color: color.myasl_orange,
                st: pathConst.searchPost
            },
            {
                title: string[lang].str_home_post_ad,
                icon: fontIcon.icon_post_add,
                color: color.green700_b,
                st: pathConst.addPost
            }
        ]

    },

    search_add_post_title: (st, lang) => {

        // const lang = dataSource.lang()

        // return st === dlConst.ST_SEARCH_POST ? string[lang].search_for : string[lang].post_new_ad_to
        return st === dlConst.ST_SEARCH_POST ? string[lang].search_for : st === dlConst.ST_ADD_POST ? string[lang].post_new_ad_to : string[lang].select_type
    },

    search_add_post: (st, lang) => {

        // const lang = dataSource.lang()

        return [
            {
                title: st === dlConst.ST_SEARCH_POST ? string[lang].buyer : string[lang].buy,
                icon: fontIcon.icon_buy,
                color: '#da59ff',
                pt: apiConst.ptBuy
            },
            {

                title: st === dlConst.ST_SEARCH_POST ? string[lang].seller : string[lang].sell,
                icon: fontIcon.icon_sell,
                color: '#73bc1c',
                pt: apiConst.ptSell
            },
            {

                title: st === dlConst.ST_SEARCH_POST ? string[lang].rental_demand : string[lang].get_on_rent,
                icon: fontIcon.icon_get_on_rent,
                color: '#10d7c2',
                pt: apiConst.ptGetOnRent
            },
            {

                title: st === dlConst.ST_SEARCH_POST ? string[lang].rental_supply : string[lang].give_on_rent,
                icon: fontIcon.icon_give_on_rent,
                color: '#4584fb',
                pt: apiConst.ptGiveOnRent

            }

        ]
    },

    unit_of_cat: (catId, lang) => {

        // const lang = dataSource.lang()
        let unitIds = []

        switch (catId) {

            case '11':
                unitIds = [30, 22, 15, 27, 29]
                break
            case '12':
                unitIds = [30, 22, 27, 29]
                break
            case '13':
                unitIds = [30, 22, 15, 27, 29]
                break
            case '14':
                unitIds = [30, 22, 27, 29]
                break
            case '15':
                unitIds = [30, 22, 27, 29]
                break
            case '16':
                unitIds = [30, 22, 27, 29]
                break
            case '17':
                unitIds = [30, 23, 19]
                break
            case '18':
                unitIds = [30, 23, 19, 16, 14]
                break
            case '19':
                unitIds = [30, 23]
                break
            case '20':
                unitIds = [30, 23]
                break
            case '21':
                unitIds = [30, 26, 24, 20, 21, 25, 17]
                break
            case '22':
                unitIds = [30, 22, 27, 29, 13]
                break
            case '23':
                unitIds = [30]
                break
            case '24':
                unitIds = [30, 22, 27, 29]
                break
            case '25':
                unitIds = [30, 28, 12, 18]
                break
            case '26':
                unitIds = [30, 11]
                break
            case '27':
                unitIds = [30, 22, 15, 27, 29]
                break
            case '28':
                unitIds = [30, 22, 15, 27, 29]
                break
            case '29':
                unitIds = [30, 22, 15]
                break
            case '30':
                unitIds = [30]
                break
        }

        let result = []

        unitIds.map((id) => {

            const obj = {
                id: id, name: string[lang].uom[id]
            }
            result.push(obj)
        })

        return result
    },

    menuList: (lang) => {
        // const lang = dataSource.lang()

        return [
            {
                title: string[lang].title_drawer_my_posts,
                icon: fontIcon.icon_my_post,
                st: pathConst.myPost
            },
            {
                title: string[lang].title_drawer_share_this_app,
                icon: fontIcon.icon_share_app,
                st: IntentUtils.getShareAppIntent()
            },
            {
                title: string[lang].title_drawer_rate_this_app,
                icon: fontIcon.icon_star,
                // st: IntentUtils.getOpenMarketyardAppIntent()
                st: pathConst.playStore
            },
            {
                title: string[lang].contact_us,
                icon: fontIcon.icon_phone,
                st: pathConst.contactUs
            },
            {
                title: string[lang].title_drawer_privacy_policy,
                icon: fontIcon.icon_padlock,
                st: pathConst.privacyPolicy
            },
            {
                title: string[lang].terms_and_conditions,
                icon: fontIcon.icon_tnc,
                st: pathConst.tnc
            }
        ]
    }

}