import React from 'react'

export class ImageWithComponentFallback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: props.src,
            fallbackIndex: 0,
            fallbacks: this.props.fallbacks ? this.props.fallbacks : [],
            component: props.component ? props.component : null,
            loadComponent: false,
        }
    }
    onError() {
        // Log.verbose(this.props.id + "errored", this.props.id + ' >> ' + this.state.fallbackIndex + ' >> ' + this.props.fallbacks.length + ' >> ' + (this.state.fallbackIndex >= this.props.fallbacks.length));
        if (this.state.fallbackIndex >= this.state.fallbacks.length) {
            this.setState({
                loadComponent: true
            });
        }
        else {
            // Log.verbose(this.props.id + "errored after", this.props.id + ' >> ' + this.state.fallbackIndex + ' >> ' + this.props.fallbacks.length + ' >> ' + (this.state.fallbackIndex >= this.props.fallbacks.length));
            this.setState((prevState, props) => ({
                src: prevState.fallbacks[prevState.fallbackIndex],
                fallbackIndex: (prevState.fallbackIndex + 1)
            }));
        }
    }
    render() {
        // Log.verbose(this.props.id + "errored render", this.props.id + ' >> ' + this.state.fallbackIndex + ' >> ' + this.props.fallbacks.length + ' >> ' + this.state.src);

        if (this.state.loadComponent) {
            return this.props.component
        }
        else {
            if (this.props.style) {
                return <img style={this.props.style} src={this.state.src} onError={() => this.onError()} />
            }
            else {
                return <img src={this.state.src} onError={() => this.onError()} />

            }
        }
    }
}