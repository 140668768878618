import { pathConst } from "../constants";

export const IntentUtils = {

    getShareMessageIntent: (shareMessage) => {
        return 'intent:#Intent;action=android.intent.action.SEND;type=text/plain;S.android.intent.extra.TEXT=' + shareMessage + ';end'
    },
    getShareAppIntent: () => {

        return IntentUtils.getShareMessageIntent(pathConst.playStore)

        // return 'intent://marketyard.org#Intent;scheme=http;package=com.globalfarm.marketyard;action=android.intent.action.SEND;type=text/plain;S.android.intent.extra.TEXT=aaaa;S.browser_fallback_url=https://www.yahoo.com;end'
    },
    getOpenMarketyardAppIntent: () => {

        return 'intent://marketyard.org#Intent;scheme=http;package=com.globalfarm.marketyard;S.browser_fallback_url=' + pathConst.playStore + ';end'
    },
    getSharePostIntent: (url) => {

        return IntentUtils.getShareMessageIntent(url)
    }
}
