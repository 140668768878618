import React, { useContext } from 'react'
import { Typography, GridList, GridListTile, Paper, Divider, Button } from "@material-ui/core"
import { dataSource, dlConst, pathConst } from '../constants'
import { color as mColor } from '../res'

import { FontIcon } from '../components'
import { NavLink } from 'react-router-dom'
import { apiInterface, apiUrl } from '../network'
import { UserContext } from '../context';
import { makeStyles } from '@material-ui/styles'
import '../App.css'
import { Log } from '../utils';
import kisan_mar from '../res/img/kisan_mar.png'

const useStyle = makeStyles(() => ({
    itemTitle: { fontSize: '1rem', fontFamily: 'regular', color: mColor.emphasis_high, padding: '8px' },
    count: {
        fontSize: '0.7rem',
        fontFamily: 'regular',
        color: mColor.accent,
        paddingTop: '5px',
        paddingRight: '5px',
        zIndex: '1',
        position: 'absolute',
        right: '0'
    }
}))

export default function Home(props) {

    const userContext = useContext(UserContext)
    const classes = useStyle()
    const postOptions = dataSource.home_post_option(userContext.lang)

    const urlParams = new URLSearchParams(props.location.search)
    const mobno = urlParams.get(dlConst.mobno)
    const auth = urlParams.get(dlConst.auth)



    if (!(userContext.user) && mobno && auth) {
        const payLoad = {
            mobilenumber: mobno,
            otp: auth
        }
        apiInterface.post(apiUrl.refreshUser, payLoad).then((res) => {

            if (window.AppInterface) {

                const webCallbackPayload = { type: 'log', message: res.data }
                window.AppInterface.webCallback(JSON.stringify(webCallbackPayload))
            }

            userContext.updateUser(res.data.data[0])
        }).catch((err) => {

        })
    }

    Log.verbose(userContext.postCount)

    if (!userContext.postCount) {
        const payLoad = {

        }
        apiInterface.post(apiUrl.postCount, payLoad).then((res) => {

            // {"status":{"value":"1","returncode":"1"},"data":[
            // {"count":"4377","type":"bp"},{"count":"9862","type":"sp"},{"count":"544","type":"norp"},
            // {"count":"802","type":"frp"},{"count":"11081","type":"biz"},{"count":"734","type":"news"},
            // {"count":"9056","type":"qa"},{"count":"574","type":"story"},{"count":"30","type":"job"},
            // {"count":"14","type":"schm"},{"count":"22","type":"event"}]}

            const counts = res.data.data

            Log.verbose(counts)
            const postCount = {}

            for (const count in counts) {

                const data = counts[count]

                if (data.type === 'bp') {
                    postCount.bp = parseInt(data.count)
                } else if (data.type === 'sp') {
                    postCount.sp = parseInt(data.count)
                } else if (data.type === 'norp') {
                    postCount.norp = parseInt(data.count)
                } else if (data.type === 'frp') {
                    postCount.frp = parseInt(data.count)
                } else if (data.type === 'biz') {
                    postCount.biz = parseInt(data.count)
                } else if (data.type === 'news') {
                    postCount.news = parseInt(data.count)
                } else if (data.type === 'qa') {
                    postCount.qa = parseInt(data.count)
                } else if (data.type === 'story') {
                    postCount.story = parseInt(data.count)
                } else if (data.type === 'job') {
                    postCount.job = parseInt(data.count)
                } else if (data.type === 'schm') {
                    postCount.schm = parseInt(data.count)
                } else if (data.type === 'event') {
                    postCount.event = parseInt(data.count)
                }
            }

            Log.verbose(postCount)
            userContext.updatePostCount(postCount)

        }).catch((err) => {

            Log.verbose(err)
        })
    }

    const printVersion = () => {

        console.log('AppVersion', 'version 2 >> ' + window.navigator.userAgent)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>

            < GridList cols={2} spacing={10} >

                {
                    postOptions.map((param) =>

                        <GridListTile key={param.st}>

                            <NavLink to={param.st} style={{ textDecoration: 'none' }}>
                                <Paper>
                                    <Typography align="right" className={classes.count}>

                                        {(param.st === pathConst.searchPost && userContext.postCount) ?

                                            userContext.postCount.bp + userContext.postCount.sp + userContext.postCount.norp +
                                            userContext.postCount.frp + userContext.postCount.biz : ''
                                        }

                                    </Typography>
                                    <FontIcon style={{ color: param.color, fontSize: '6rem' }} fontIcon={param.icon} />
                                    <Divider />
                                    <Typography align="center" className={classes.itemTitle}>{param.title}</Typography>

                                </Paper>
                            </NavLink>
                            {/* scheme and host must match exact in manifest need to add https and m. support in manifest*/}
                            {/* below is the go to app intent support in chrome if not installed will go to playstore */}
                            {/* 'intent://marketyard.org#Intent;scheme=http;package=com.globalfarm.marketyard;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.globalfarm.marketyard;end' */}
                            {/* <Button href={'intent://m.marketyard.org#Intent;scheme=http;package=com.globalfarm.marketyard;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.globalfarm.marketyard;end'}>{param.title}</Button> */}
                            {/* <Button href={'intent:#Intent;action=android.intent.action.SEND;type=text/plain;S.android.intent.extra.TEXT=xyz;S.browser_fallback_url=https://www.yahoo.com;end'}>{param.title}</Button> */}
                        </GridListTile>
                    )
                }
            </GridList >
            {/* <NavLink to={'/kisanpass'} style={{ textDecoration: 'none' }}>
                <img style={{ width: '100%', height: '50%', margin: '15px 0px 0px 0px' }} src={kisan_mar} />
            </NavLink> */}
        </div>
    );
}