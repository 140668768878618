import { langCode as lc } from '../constants'

export const string = {

    [lc.eng]: {

        marketyard: 'MARKETYARD',
        rupee_symbol: '₹',

        uom: {
            11: '100 Piece',
            12: 'Acre',
            13: 'Bag',
            14: 'Day',
            15: 'Dozen',
            16: 'Ft',
            17: 'gsm',
            18: 'Hectare',
            19: 'Hour',
            20: 'HP',
            21: 'Inch',
            22: 'Kg',
            23: 'KM',
            24: 'KVA',
            25: 'Litres',
            26: 'Mtr',
            27: 'Quintals',
            28: 'Sq. Ft',
            29: 'Tonnes',
            30: 'Unit',
            31: 'Other',
        },

        str_home_search_ad: 'Search Posts',
        str_home_post_ad: 'Submit New Post',
        search_for: 'Search For',
        post_new_ad_to: 'Submit New Post To',
        buyer: 'Buyer',
        seller: 'Seller',
        rental_demand: 'Rental Demand',
        rental_supply: 'Rental Supply',
        buy: 'Buy',
        sell: 'Sell',
        get_on_rent: 'Get On Rent',
        give_on_rent: 'Give On Rent',
        title_drawer_my_posts: 'My Posts',
        title_drawer_manage_business: 'Manage Business',
        title_drawer_advertise_with_us: 'Advertise with us',
        title_drawer_share_this_app: 'Share This App',
        title_drawer_rate_this_app: 'Rate This App',
        contact_us: 'Contact Us : 9763335000',
        guideline_video: 'Guideline Videos',
        title_drawer_privacy_policy: 'Privacy Policy',
        title_drawer_switch_user: 'Switch User',
        terms_and_conditions: 'Terms & Conditions',
        select_type: 'Select Type',

        Select_Variant: 'Select Variant',
        Please_select_a_variant: 'Please select a Variant',
        product_quantity: 'Product Quantity',
        quantity: 'Quantity',
        spinner_prompt: 'Select Unit',
        Please_fill_the_quantity: 'Please fill the Quantity',
        Please_select_a_unit: 'Please select a Unit',
        enter_expected_price: 'Enter Expected Price',
        per: 'Per',
        Please_fill_the_price: 'Please fill the Price',
        organic: 'Organic',
        residue_free: 'Residue Free',
        fpo: 'FPO',
        str_add_post_product_description: 'Product Description',
        next: 'NEXT',
        price_colon: 'Expected Price :',
        quantity_colon: 'Quantity :',
        post_at_location: 'Post at location :',
        submit: 'Submit',
        select_location: 'Select Location',
        select_State: 'Select State',
        select_District: 'Select District',
        select_Taluka: 'Select Taluka',
        cancel: 'Cancel',
        ok: 'OK',
        Post_successfully_added: 'Post successfully added !',
        Call: 'Call',
        filter: 'Apply Filter',
        No_Products_Found: 'No result found matching your search request !',

        str_button_recent: 'Recent',
        str_button_price: 'Price',
        str_button_ratings: 'Ratings',
        please_select_a_state: 'Please select a state',
        please_select_a_district: 'Please select a district',
        please_select_a_taluka: 'Please select a taluka',
        get_search_results: 'Get Search Result',
        please_login_to_use_this: 'Please Login to continue..',

        first_name: 'First Name',
        last_name: 'Last Name',
        mobile_no: 'Mobile No.',
        please_enter_your_first_name: 'Please enter first name',
        please_enter_your_last_name: 'Please enter last name',
        please_enter_your_mobile_no: 'Please enter mobile no.',
        please_enter_a_valid_mobile_no: 'Please enter a valid mobile no.',

        State: 'State',
        District: 'District',
        submit:'Submit',

    },
    [lc.mar]: {

        uom: {
            11: '१०० नग ',
            12: 'एकर',
            13: 'पिशवी',
            14: 'दिवस',
            15: 'डझन',
            16: 'फूट',
            17: 'जीएसएम ',
            18: 'हेक्टर',
            19: 'तास',
            20: 'एच.पी. ',
            21: 'इंच',
            22: 'कि.ग्रॅ.',
            23: 'कि.मी. ',
            24: 'के.व्ही.ए . ',
            25: 'लिटर',
            26: 'मीटर',
            27: 'क्विंटल',
            28: 'स्क्वे.फू. ',
            29: 'टन',
            30: 'एकक',
            31: 'इतर',
        },
        str_home_search_ad: 'पोस्ट शोधा',
        str_home_post_ad: 'नवीन पोस्ट टाका',
        search_for: 'पोस्ट शोधा',
        post_new_ad_to: 'नवीन पोस्ट टाका',
        buyer: 'खरेदीदार',
        seller: 'विक्रेते',
        rental_demand: 'भाड्याने घेणारे',
        rental_supply: 'भाडयाने देणारे',
        buy: 'विकत घेणे',
        sell: 'विकत देणे',
        get_on_rent: 'भाड्याने घेणे',
        give_on_rent: 'भाड्याने देणे',
        title_drawer_my_posts: 'माझ्या पोस्ट',
        title_drawer_manage_business: 'व्यवसाय व्यवस्थापन',
        title_drawer_advertise_with_us: 'अँप वरती जाहिरात करा',
        title_drawer_share_this_app: 'अँप शेअर करा',
        title_drawer_rate_this_app: 'अँप ला रेटिंग द्या',
        contact_us: 'संपर्क : 9763335000',
        guideline_video: 'मार्गदर्शक विडिओ',
        title_drawer_privacy_policy: 'गोपनीयता',
        title_drawer_switch_user: 'स्विच करा',
        terms_and_conditions: 'नियम व आटी',
        select_type: 'प्रकार निवडा',

        Select_Variant: 'प्रकार निवडा',
        Please_select_a_variant: 'कृपया प्रकार निवडा',
        product_quantity: 'प्रमाण किंवा संख्या',
        quantity: 'प्रमाण',
        spinner_prompt: 'एकक निवडा',
        Please_fill_the_quantity: 'कृपया प्रमाण भरा',
        Please_select_a_unit: 'कृपया एक एकक निवडा',
        enter_expected_price: 'अपेक्षित किंमत टाका',
        per: 'प्रति',
        Please_fill_the_price: 'कृपया किंमत भरा',
        organic: 'सेंद्रिय',
        residue_free: 'रेसिडयु फ्री',
        fpo: 'एफपीओ',
        str_add_post_product_description: 'उत्पादन वर्णन',
        next: 'पुढे जा',
        price_colon: 'अपेक्षित किंमत :',
        quantity_colon: 'प्रमाण :',
        post_at_location: 'पोस्टचे ठिकाण :',

        submit: 'सबमिट करा',
        select_location: 'स्थान निवडा',
        select_State: 'राज्य निवडा',
        select_District: 'जिल्हा निवडा',
        select_Taluka: 'तालुका निवडा',
        cancel: 'रद्द करा',
        ok: 'ठीक आहे',
        Post_successfully_added: 'यशस्वीरित्या पोस्ट झाली आहे !',
        Call: 'कॉल करा',
        filter: 'फिल्टर करा',
        No_Products_Found: 'कोणतीही पोस्ट आपल्या शोध विनंतीस उपलब्ध नाही !',

        str_button_recent: 'वेळेनुसार',
        str_button_price: 'किमतीनुसार',
        str_button_ratings: 'रेटिंगनुसार',
        please_select_a_state: 'कृपया राज्य निवडा',
        please_select_a_district: 'कृपया जिल्हा निवडा',
        please_select_a_taluka: 'कृपया तालुका निवडा',
        get_search_results: 'शोधा',
        please_login_to_use_this: 'सुरू ठेवण्यासाठी कृपया लॉगिन करा..',

        first_name: 'नाव',
        last_name: 'आडनाव',
        mobile_no: 'मोबाइल नंबर',
        please_enter_your_first_name: 'कृपया नाव टाका',
        please_enter_your_last_name: 'कृपया आडनाव टाका',
        please_enter_your_mobile_no: 'कृपया मोबाइल नंबर टाका',
        please_enter_a_valid_mobile_no: 'कृपया वैध मोबाइल नंबर टाका',

        State: 'राज्य',
        District: 'जिल्हा',
        submit:'सबमिट करा',
        

    },
    [lc.hin]: {

        uom: {
            11: '१०० नग',
            12: 'एकड़',
            13: 'बैग',
            14: 'दिन',
            15: 'दर्जन',
            16: 'फूट',
            17: 'जीएसएम ',
            18: 'हैक्टर',
            19: 'घंटा',
            20: 'एच.पी. ',
            21: 'इंच',
            22: 'किलोग्राम',
            23: 'कि.मी. ',
            24: 'के.व्ही.ए . ',
            25: 'लीटर',
            26: 'मीटर',
            27: 'क्विंटल',
            28: 'स्क्वे.फू. ',
            29: 'टन',
            30: 'इकाई',
            31: 'अन्य',
        },

        spinner_prompt: 'इकाई का चयन करें',
        Please_fill_the_quantity: 'कृपया प्रमाण दर्ज करे',
        Please_select_a_unit: 'कृपया एक इकाई का चयन करें',
        enter_expected_price: 'अपेक्षित भाव / मूल्य दर्ज करें',
        per: 'प्रति',
        Please_fill_the_price: 'कृपया मूल्य दर्ज करे',
        organic: 'कार्बनिक',
        residue_free: 'अवशेष मुक्त',
        fpo: 'एफपीओ',
        str_add_post_product_description: 'उत्पाद वर्णन',
        next: 'आगे बढ़ो',
        price_colon: 'अपेक्षित मूल्य:',
        quantity_colon: 'मात्रा:',
        post_at_location: 'पोस्ट का स्थान :',

        submit: 'जमा करें',
        select_location: 'स्थान चुनें',
        select_State: 'राज्य का चयन करें',
        select_District: 'जिले का चयन करें',
        select_Taluka: 'तहसिल का चयन करें',
        cancel: 'रद्द करे',
        ok: 'ठीक है',
        Post_successfully_added: 'सफलतापूर्वक पोस्ट किया गया!',
        Call: 'कॉल करे',
        filter: 'फिल्टर लागू करें',
        No_Products_Found: 'आपके खोज अनुरोध पर कोई पोस्ट उपलब्ध नहीं है!',

        str_button_recent: 'नवीनतम',
        str_button_price: 'किमत अनुसार ',
        str_button_ratings: 'रेटिंग अनुसार ',
        please_select_a_state: 'कृपया राज्य का चयन करें',
        please_select_a_district: 'कृपया जिले का चयन करें',
        please_select_a_taluka: 'कृपया तहसिल का चयन करें',
        get_search_results: 'खोज परिणाम प्राप्त करें',
        please_login_to_use_this: 'जारी रखने के लिए कृपया लॉग इन करें..',

        first_name: 'नाम',
        last_name: 'उपनाम',
        mobile_no: 'मोबाइल नंबर',
        please_enter_your_first_name: 'कृपया नाम दर्ज करें',
        please_enter_your_last_name: 'कृपया उपनाम दर्ज करें',
        please_enter_your_mobile_no: 'कृपया अपना मोबाइल नंबर दर्ज करें',
        please_enter_a_valid_mobile_no: 'कृपया अपना वैध मोबाइल नंबर दर्ज करें',

        State: 'राज्य',
        District: 'जिला',
        submit:'जमा करें',
    }


}