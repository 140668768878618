import { color } from './color'

export const style = {
    home_option_post: {
        fontFamily: 'bold',
        fontSize: '50px',
        color: color.gray500_b,
        backgroundColor: color.primary,
    },
    test2: {

    }
}