import React, { useState } from 'react';
import { MyCircularProgress } from '../components'
import { Log } from '../utils';

const Forms = ({ form }) => {

    const [isLoading, setLoading] = useState(true);


    if (isLoading) {
        fetch(`${process.env.PUBLIC_URL}/forms.json`).then((res) => {
            return res.json();
        }).then((res) => {

            Log.verbose(res)
            var formUrl = res[form];

            if (formUrl) {

                var name = getCookie('name');
                var mobno = getCookie('mobno');
                var location = getCookie('location');

                if (name.length > 0 && mobno.length > 0 && location.length > 0) {

                    formUrl = formUrl.replace("location", location);
                    formUrl = formUrl.replace("mobno", mobno);
                    formUrl = formUrl.replace("name", name);
                } else if (formUrl.indexOf("?") != -1) {

                    formUrl = formUrl.split("?")[0]
                }

                Log.verbose(formUrl);

                // setFormUrl(formUrl);
                // window.open(formUrl, '_parent')
                window.open(formUrl, '_self');
            } else {

                window.open("/m", "_self");
            }

        }).catch((e) => {

            Log.verbose(e)
            window.open("/m", "_self");

        });
        // .finally(() => setLoading(false))
    }


    return (

        <>

            {isLoading ? <MyCircularProgress fullScreen /> : (

                null


                // <iframe title={'form'} src={formUrl} width="640" height="635" frameborder="0" marginheight="0" marginwidth="0">Loading</iframe>
            )}
        </>
    );

}

const getHeaders = () => {
    var req = new XMLHttpRequest();

    req.open('GET', document.location, false);
    req.setRequestHeader("setHeader", "getheader");
    req.send(null);

    // associate array to store all values
    var data = new Object();

    // get all headers in one call and parse each item

    var headers = req.getAllResponseHeaders().toLowerCase();

    var aHeaders = headers.split('\n');
    var i = 0;
    for (i = 0; i < aHeaders.length; i++) {
        var thisItem = aHeaders[i];
        var key = thisItem.substring(0, thisItem.indexOf(':'));
        var value = thisItem.substring(thisItem.indexOf(':') + 1);
        data[key] = value;
    }

    // get referer
    var referer = document.referrer;
    data["Referer"] = referer;

    //get useragent
    var useragent = navigator.userAgent;
    data["UserAgent"] = useragent;


    //extra code to display the values in html
    var display = "";
    for (var key in data) {
        if (key != "")
            display += key + " : " + data[key] + "\n";
    }
    Log.verbose(display);
}

const setCookie = (cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    var value = encodeURIComponent(cvalue);
    document.cookie = cname + "=" + value + ";" + expires;
}

const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {

            // var value = decodeURIComponent(c.substring(name.length, c.length));
            var value = c.substring(name.length, c.length);
            return value;
        }
    }
    return "";
}


export default Forms;