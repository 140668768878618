import { Log } from ".";

export const LocationUtils = {

    getLocDetails: (lid, lefullname, llfullname) => {


        Log.verbose('getLocDetails', lid + ' >> ' + lefullname + ' >> ' + llfullname)

        if (!lid || lid.trim().length === 0) {

            return null
        }

        let state = {}
        let district
        let taluka

        let lename = ['', '', '']
        let llname = ['', '', '']

        if (lefullname && (lefullname.length > 0)) {

            if (lefullname.includes('|')) {

                const arr = lefullname.split('|')

                for (let index = 0; index < arr.length; index++) {
                    lename[index] = arr[index].trim();

                }

            }
            else {
                lename[0] = lefullname
            }
        }

        if (llfullname && (llfullname.length > 0)) {

            if (llfullname.includes('|')) {

                const arr = llfullname.split('|')

                for (let index = 0; index < arr.length; index++) {
                    llname[index] = arr[index].trim();

                }

            }
            else {
                lename[0] = llfullname
            }
        }

        state.lename = lename[0]
        state.llname = llname[0]
        state.lefullname = lename[0]
        state.llfullname = llname[0]

        Log.verbose('getLocDetails', (lid.substring(2, 4) === '00'))

        if (lid.substring(2, 4) === '00') {
            state.lid = lid
        }
        else {
            state.lid = lid.substring(0, 2) + '0000000'

            district = {}

            district.lename = lename[1]
            district.llname = llname[1]
            district.lefullname = lename[0] + ' | ' + lename[1]
            district.llfullname = llname[0] + ' | ' + llname[1]

            Log.verbose('getLocDetails', (lid.substring(4, 6) === '00'))

            if (lid.substring(4, 6) === '00') {
                district.lid = lid
            }
            else {
                district.lid = lid.substring(0, 4) + '00000'

                taluka = {}

                taluka.lename = lename[2]
                taluka.llname = llname[2]
                taluka.lefullname = lename[0] + ' | ' + lename[1] + ' | ' + lename[2]
                taluka.llfullname = llname[0] + ' | ' + llname[1] + ' | ' + llname[2]

                Log.verbose('getLocDetails', (lid.substring(4, 6) === '00'))

                if (lid.substring(4, 6) === '00') {
                    taluka.lid = lid
                }
                else {
                    taluka.lid = lid.substring(0, 6) + '000'
                }
            }
        }

        Log.verbose('getLocDetails >> state: ', state)
        Log.verbose('getLocDetails >> district: ', district)
        Log.verbose('getLocDetails taluka: ', taluka)

        return { state: state, district: district, taluka: taluka }
    }
}