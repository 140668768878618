import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import { Test, Home, PostType, ProductList, PostListing, PostDetail, MyPost, KisanPass, Forms } from './pages'
import { Log } from './utils'
import { dlConst, apiConst, pathConst } from './constants'
import { UserStore } from './context'
import { Container } from '@material-ui/core';
import { ToolBar } from './components';
import AddPost from './pages/AddPost';

const TAG = 'App'

class App extends React.Component {

  render() {
    console.log('AppVersion', 'version 2.2.1')


    Log.verbose('AppVersion', 'version 2.2.1 >> ' + window.navigator.userAgent)

    if (window.AppInterface) {
      const webCallbackPayload = { type: 'log', message: window.navigator.userAgent }
      window.AppInterface.webCallback(JSON.stringify(webCallbackPayload))
    }
    Log.verbose(TAG, JSON.stringify(this.props))
    return (
      // <Container maxWidth="xs" style={{ backgroundColor: '#e2e2e2', padding: '0px', minHeight: '100vh' }}>
      <Container maxWidth="xs" style={{ backgroundColor: '#f5f5f5', padding: '0px', minHeight: '100vh' }}>
        {/* <Container maxWidth="xs" style={{ backgroundColor: '#ffffff', padding: '0px', minHeight: '100vh' }}> */}
        <UserStore>

          <BrowserRouter basename='/m/'>
            <ToolBar />
            <Container maxWidth="xs" style={{ padding: '10px' }}>
              <Switch>

                <Route path={'/kisanpass'} exact render={({ history, location }) => {

                  // const urlParams = new URLSearchParams(location.search)
                  // const isAk = urlParams.get(dlConst.ref) === dlConst.amhi_kastkar

                  return <KisanPass isAk={false} />
                }} />

                <Route path={'/forms'} exact render={(props) => {

                  // console.log(props)
                  // console.log(window.Headers)
                  const urlParams = new URLSearchParams(props.location.search)
                  const form = urlParams.get(dlConst.form)

                  return <Forms form={form} />
                }} />

                <Route path={'/'} render={({ history, location }) => {

                  const urlParams = new URLSearchParams(location.search)
                  const st = urlParams.get(dlConst.st)

                  Log.verbose('for_m >> st: ' + st)

                  if (st) {

                    const pt = urlParams.get(dlConst.type)

                    if (st === dlConst.ST_SEARCH_POST || st === dlConst.ST_ADD_POST) {

                      const pid = urlParams.get(dlConst.pid)

                      if (pt && (pt === apiConst.ptBuy || pt === apiConst.ptSell
                        || pt === apiConst.ptGiveOnRent || pt === apiConst.ptGetOnRent)) {

                        if (pid && !pid.endsWith('0000')) {
                          if (st === dlConst.ST_SEARCH_POST) {
                            return <PostListing pt={pt} pid={pid} location={location} history={history} />
                          }
                          else if (st === dlConst.ST_ADD_POST) {
                            return <AddPost pid={pid} pt={pt} history={history} location={location} />
                          }
                        }
                        else {
                          return <ProductList st={st} pt={pt} pid={pid} />
                        }
                      }
                      else {
                        return <PostType st={st} />
                      }

                    } else if (st === dlConst.ST_PRODUCT_DETAIL) {

                      const id = urlParams.get(dlConst.id)

                      if (pt && (pt === apiConst.ptBuy || pt === apiConst.ptSell
                        || pt === apiConst.ptGiveOnRent || pt === apiConst.ptGetOnRent)
                        && id) {
                        return <PostDetail location={location} pt={pt} id={id} />
                      }
                      else {
                        return <PostType st={st} />
                      }

                    } else if (st === dlConst.ST_MY_POST) {

                      if (pt && (pt === apiConst.ptBuy || pt === apiConst.ptSell
                        || pt === apiConst.ptGiveOnRent || pt === apiConst.ptGetOnRent)) {
                        return <MyPost location={location} pt={pt} />
                      }
                      else {
                        return <PostType st={st} />
                      }

                    } else {
                      return <Home history={history} location={location} />
                    }

                  }
                  else {
                    return <Home history={history} location={location} />
                  }
                }} />




              </Switch>
            </Container>

          </BrowserRouter>
        </UserStore>
      </Container>

    );
  }
}

export default App;


// // 
// //  {/* <Route path={pathConst.home} exact component={Home} /> */}
// <Route path={pathConst.test} exact component={Test} />
// <Route path={pathConst.searchPost} exact render={
//   (route) => {
//     Log.verbose(route)
//     const { location } = route
//     const urlParams = new URLSearchParams(location.search)
//     const pt = urlParams.get(dlConst.type)
//     const pid = urlParams.get(dlConst.pid)

//     if (pt && (pt === apiConst.ptBuy || pt === apiConst.ptSell
//       || pt === apiConst.ptGiveOnRent || pt === apiConst.ptGetOnRent)) {

//       if (pid && !pid.endsWith('0000')) {
//         return <PostListing pt={pt} pid={pid} location={location} history={route.history} />
//       }
//       else {
//         return <ProductList st={dlConst.ST_SEARCH_POST} pt={pt} pid={pid} />
//       }
//     }
//     else {
//       return <PostType st={dlConst.ST_SEARCH_POST} />
//     }

//   }

// } />
// <Route path={pathConst.addPost} exact render={
//   (route) => {

//     Log.verbose(route)
//     const { history, location } = route
//     const urlParams = new URLSearchParams(location.search)
//     const pt = urlParams.get(dlConst.type)
//     const pid = urlParams.get(dlConst.pid)

//     if (pt && (pt === apiConst.ptBuy || pt === apiConst.ptSell
//       || pt === apiConst.ptGiveOnRent || pt === apiConst.ptGetOnRent)) {

//       if (pid && !pid.endsWith('0000')) {
//         return <AddPost pid={pid} pt={pt} history={history} location={location} />
//       }
//       else {
//         return <ProductList st={dlConst.ST_ADD_POST} pt={pt} pid={pid} />
//       }
//     }
//     else {
//       return <PostType st={dlConst.ST_ADD_POST} />
//     }

//   }

// } />

// {/* <Route path={'/' + dlConst.ST_SEARCH_LISTING} exact render={
//   ({ location }) => {

//     const urlParams = new URLSearchParams(location.search)
//     const pt = urlParams.get(dlConst.type)
//     const pid = urlParams.get(dlConst.pid)

//     if (pt && (pt === apiConst.ptBuy || pt === apiConst.ptSell
//       || pt === apiConst.ptGiveOnRent || pt === apiConst.ptGetOnRent)
//       && pid && pid.endsWith('00')) {
//       return <PostListing pt={pt} pid={pid} />
//     }
//     else {
//       return <PostType st={dlConst.ST_SEARCH_POST} />
//     }

//   }

// } /> */}

// <Route path={'/' + dlConst.ST_PRODUCT_DETAIL} exact render={
//   ({ location }) => {

//     const urlParams = new URLSearchParams(location.search)
//     const pt = urlParams.get(dlConst.type)
//     const id = urlParams.get(dlConst.id)

//     if (pt && (pt === apiConst.ptBuy || pt === apiConst.ptSell
//       || pt === apiConst.ptGiveOnRent || pt === apiConst.ptGetOnRent)
//       && id) {
//       return <PostDetail location={location} pt={pt} id={id} />
//     }
//     else {
//       return <PostType st={dlConst.ST_SEARCH_POST} />
//     }

//   }

// } />

// <Route path={'/' + dlConst.ST_MY_POST} exact render={
//   ({ location }) => {

//     const urlParams = new URLSearchParams(location.search)
//     const pt = urlParams.get(dlConst.type)

//     if (pt && (pt === apiConst.ptBuy || pt === apiConst.ptSell
//       || pt === apiConst.ptGiveOnRent || pt === apiConst.ptGetOnRent)) {
//       return <MyPost location={location} pt={pt} />
//     }
//     else {
//       return <PostType st={dlConst.ST_MY_POST} />
//     }

//   }

// } />

// {/* <Redirect from='/*' to='/' /> */}
// {/* <Route component={Home} /> */}


