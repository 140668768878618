import React from 'react'
import { NavLink } from 'react-router-dom'
import { dlConst, apiConst, pathConst } from '../constants'
import { makeStyles, Typography, GridList, GridListTile, Paper } from '@material-ui/core';
import { MyCircularProgress } from '../components'
import { color as mColor, style } from '../res'
import { apiInterface, apiUrl } from '../network'
import '../App.css'
import defaultImg from '../res/img/m_default_gray_leaf.jpg'

const useStyles = makeStyles(theme => ({

    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        maxWidth: 'xs',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 450,
    },
    count: {
        fontSize: '0.7rem',
        fontFamily: 'regular',
        color: mColor.accent,
        paddingTop: '5px',
        paddingRight: '5px',
        zIndex: '1',
        position: 'absolute',
        right: '0',
        top: 0
    }

}));


export default class ProductList extends React.Component {

    state = {
        prodList: [],
        loading: true,
    }

    componentDidMount() {
        this.makeRequest()
    }

    // componentWillReceiveProps(nextProps) {
    //     Log.verbose(TAG, 'componentWillReceiveProps >> ' + JSON.stringify(nextProps))
    // }

    // static getDerivedStateFromProps(props, state) {
    //     Log.verbose(TAG, 'getDerivedStateFromProps >> ' + JSON.stringify(props) + ' >> ' + JSON.stringify(state))

    //     if (props.pid !== state.pid) {

    //         Log.verbose(TAG, props.pid + ' >> ' + state.pid)
    //         return {
    //             loading: props.pid > state.pid,
    //             pid: props.pid
    //         }

    //     }

    //     return null
    // }

    componentDidUpdate(prevProps, prevState) {
        // Log.verbose(TAG, 'componentDidUpdate >> ' + JSON.stringify(prevProps) + ' >> ' + JSON.stringify(prevState))
        // Log.verbose(TAG, 'componentDidUpdate >> ' + JSON.stringify(this.props) + ' >> ' + JSON.stringify(this.state))

        if (prevProps.pid !== this.props.pid) {

            this.setState({
                loading: true
            })
            this.makeRequest()
        }
    }

    makeRequest = () => {

        const pid = this.props.pid
        const payLoad = { pinnumber: '-1', uid: '-1' }

        if (pid) {
            payLoad.pid = pid
        }

        apiInterface.post(apiUrl.getProd, payLoad)
            .then((res) => {

                this.setState({
                    loading: false,
                    prodList: res.data.data,
                })

            })
            .catch((err) => {
            })

    }


    render() {

        // const classes = useStyles()
        const { loading, prodList } = this.state
        const { st, pt } = this.props

        return (
            !loading ? <div >

                <GridList cols={2} spacing={10} cellHeight='auto'>
                    {prodList.map(prod => {

                        if (pt === apiConst.ptBuy || pt === apiConst.ptSell
                            || ((pt === apiConst.ptGetOnRent || pt === apiConst.ptGiveOnRent) && prod.rentable === apiConst.rentable)) {

                            if (st === dlConst.ST_ADD_POST || ((
                                st === dlConst.ST_SEARCH_POST && (
                                    (pt === apiConst.ptBuy && prod.bpqty > 0)
                                    || (pt === apiConst.ptSell && prod.spqty > 0)

                                    || (pt === apiConst.ptGiveOnRent && prod.rpqty > 0)

                                    || (pt === apiConst.ptGetOnRent && prod.nrqty > 0)

                                )))) {
                                return < GridListTile key={prod.pid} cols={1} >
                                    <PostCard prod={prod} {...this.props} />
                                </GridListTile>
                            }
                        }

                    }

                    )}
                </GridList>
            </div> : <MyCircularProgress fullScreen />

        )
    }
}


function PostCard(props) {


    const { pid, pename, plname, rentable, spqty, bpqty, rpqty, nrqty } = props.prod
    const { st, pt } = props
    const classes = useStyles()

    return (

        <NavLink to={pathConst.prodListing(props.st, pt, pid)} style={{ textDecoration: 'none' }}>
            <Paper >

                <img onError={(e) => e.target.src = defaultImg} style={{ width: '100%', height: '100%' }} src={apiUrl.prodImages(pid)} alt='logo' />

                <Typography style={{ ...style.test, color: mColor.emphasis_high, position: 'absolute', zIndex: '1', top: 0, padding: 5 }}>{pename}</Typography>

                {st === dlConst.ST_SEARCH_POST && <Typography className={classes.count} >

                    {pt === apiConst.ptBuy && bpqty}

                    {pt === apiConst.ptSell && spqty}

                    {pt === apiConst.ptGiveOnRent && rpqty}

                    {pt === apiConst.ptGetOnRent && nrqty}

                </Typography>}

                <Typography style={{ ...style.test, color: mColor.emphasis_high, position: 'absolute', zIndex: '1', right: 0, bottom: 0, padding: 5 }}>{plname}</Typography>
            </Paper >
        </NavLink>
    )


}