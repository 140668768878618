import { dlConst } from './dlConst'

export const pathConst = {
    home: '/',
    test: '/test',
    searchPost: '/?st=' + dlConst.ST_SEARCH_POST,
    addPost: '/?st=' + dlConst.ST_ADD_POST,
    shareThisApp: dlConst.ST_SHARE_THIS_APP,
    prodListing: (st, pt, pid) => {

        const url = '/?st=' + st

        const urlParams = new URLSearchParams()

        urlParams.set(dlConst.type, pt)

        if (pid) {

            urlParams.set(dlConst.pid, pid)

            // if (!pid.endsWith('0000')) {

            //     if (st === dlConst.ST_SEARCH_POST) {
            //         const url = '/' + dlConst.ST_SEARCH_LISTING
            //         return url + '?' + urlParams
            //     }

            // }
        }

        return url + '&' + urlParams
    },
    postDetail: (pt, id) => {

        const url = '/?st=' + dlConst.ST_PRODUCT_DETAIL

        const urlParams = new URLSearchParams()

        urlParams.set(dlConst.type, pt)
        urlParams.set(dlConst.id, id)

        return url + '&' + urlParams
    },
    myPost: '/m/?st=' + dlConst.ST_MY_POST,
    privacyPolicy: 'http://marketyard.org/privacy.html',
    tnc: 'http://marketyard.org/terms.html',
    contactUs: 'tel: 9763335000',
    playStore: 'https://play.google.com/store/apps/details?id=com.globalfarm.marketyard'

}