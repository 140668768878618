import React from 'react'
import { NavLink } from 'react-router-dom'
import { apiConst, pathConst, langCode } from '../constants'
import { Typography, GridList, GridListTile, Paper, Container, Button, Dialog, FormControl, Select, OutlinedInput, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { InfiniteScrollExtend, FontIcon, MyCircularProgress, ImageWithFallbacks, ImageWithComponentFallback, ToolBar } from '../components'
import { color as mColor, fontIcon, string } from '../res'
import { apiInterface, apiUrl } from '../network'
import { UserContext } from '../context'
import defaultImg from '../res/img/m_default_gray_leaf.jpg'
import '../App.css'
import { Log } from '../utils';


const fetchSize = 20

export default class PostDetail extends React.Component {

    static contextType = UserContext

    constructor(props, context) {
        super(props, context)

        this.state = {
            postList: [],
            loading: false,
            loadMore: true,
            noPostFound: false,
        }


    }

    makeRequest = (pageNo) => {

        Log.verbose('makeRequest', pageNo)

        const payLoad = { "pinnumber": this.context.pinnumber, "uid": this.context.uid, "posttype": this.props.pt, "sortby": apiConst.sbDate, "sortbyorder": apiConst.sbOrderDsc, "fetchcount": pageNo, "fetchsize": fetchSize }

        apiInterface.post(apiUrl.myPost, payLoad)
            .then((res) => {

                if (res.data.status.returncode === '0') {

                    if (pageNo > 1) {
                        this.setState({
                            loading: false,
                            loadMore: false,
                            noPostFound: false

                        })
                    } else {

                        this.setState({
                            loading: false,
                            loadMore: false,
                            noPostFound: true

                        })
                    }
                }
                else {
                    this.setState({
                        loading: false,
                        postList: [...this.state.postList, ...res.data.data],
                        loadMore: res.data.data.length === fetchSize
                    })
                }


            })
            .catch((err) => {
            })

    }

    render() {

        const { loadMore, postList, noPostFound } = this.state
        const { lang, user } = this.context
        const loader = <MyCircularProgress />

        Log.verbose(this.props)
        Log.verbose(this.state)
        Log.verbose(this.context)

        return (!user ? <Typography style={{ color: mColor.emphasis_medium, fontFamily: 'regular', fontSize: '0.9rem', fontWeight: '500' }}>{string[lang].please_login_to_use_this}</Typography> : (noPostFound ? <Typography style={{ color: mColor.emphasis_medium, fontFamily: 'regular', fontSize: '0.9rem', fontWeight: '500' }}>{string[lang].No_Products_Found}</Typography> :
            <InfiniteScrollExtend
                pageStart={0}
                loadMore={this.makeRequest}
                hasMore={loadMore}
                loader={loader}
                threshold={300}>

                <GridList cols={1} spacing={10} cellHeight='auto'>
                    {postList.map((post, i) => {

                        return < GridListTile key={post.id} cols={1} >
                            <PostCard post={post} {...this.props} i={i} context={this.context} />
                        </GridListTile>

                    }

                    )}
                </GridList>
            </InfiniteScrollExtend>))
    }
}

class PostCard extends React.Component {

    handleDeleteClick = () => {

        Log.verbose('handleMobileClick >> id: ' + this.props.post.id)
        const payLoad = { "pinnumber": this.props.context.pinnumber, "uid": this.props.context.uid, "posttype": this.props.pt, "id": this.props.post.id }


        apiInterface.post(apiUrl.deletePost, payLoad)
            .then((res) => {

                if (res.data.status.returncode === '0') {

                    this.setState({
                        loading: false,
                        loadMore: false,
                    })
                }
                else {
                    this.setState({
                        loading: false,
                        postList: [...this.state.postList, ...res.data.data],
                        loadMore: res.data.data.length === fetchSize
                    })
                }


            })
            .catch((err) => {
            })

    }


    render() {

        //         "id":"1559133457670",
        // "recdate":"2019-05-29 18:07:37.0",
        // "price":"60",
        // "qty":"2",
        // "uom":"Kg",
        // "uom2":"Kg",
        // "rating":"3.0",
        // "haspic":"false",
        // "lid":"253302014",
        // "pid":"110299",
        // "isorganic":"true",
        // "isrfree":"false",
        // "isfpo":"false",
        // "locename":"Thane | Ambarnath | Chamtoli",
        // "loclname":"ठाणे | अंबरनाथ | चमटोली",
        // "prod":"मराठीOther Acai Berry",
        // "prod2":"Other Acai Berry",
        // "username":"नवनाथ",
        // "lastname":"काटकर",
        // "uid":"1559116050980"




        const { pt, i } = this.props
        const { lang } = this.props.context

        let { id, recdate, price, qty, uom, uom2, priceuom, qtyuom, rating, haspic, lid,
            pid, isorganic, isrfree, isfpo, locename, loclname, prod, prod2, username, lastname, uid } = this.props.post

        isorganic = isorganic === 'true'
        isrfree = isrfree === 'true'
        isfpo = isfpo === 'true'

        const date = new Date(recdate)
        const postDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()


        let showDeleteDialog = false;

        let state = window.history.state.state;

        Log.verbose(state)

        if (state && 'showDeleteDialog' in state) {
            showDeleteDialog = state.showDeleteDialog;
        }

        Log.verbose('showDeleteDialog >> ' + showDeleteDialog)

        Log.verbose(this.props)

        return (

            <NavLink to={pathConst.postDetail(pt, id)} style={{ textDecoration: 'none' }}>
                <Paper style={{ display: 'flex', padding: '10px', flexDirection: 'row', alignItems: 'center' }}>


                    <ImageWithFallbacks style={{ width: '30%', height: '30%', border: '1px solid #e2e2e2', marginRight: '10px' }}
                        src={apiUrl.postDetailImage(haspic, id, pid, pt)} fallbacks={[apiUrl.prodImages(pid), defaultImg]} />

                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1' }}>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ flexGrow: '1', color: mColor.emphasis_high, fontFamily: 'bold', fontSize: '0.9rem', fontWeight: '600' }}></Typography>
                            <Typography style={{ color: mColor.emphasis_medium, fontSize: '0.7rem', fontFamily: 'regular' }}>{postDate}</Typography>
                        </div>
                        <Typography style={{ color: mColor.emphasis_medium, fontFamily: 'regular', fontSize: '0.9rem', fontWeight: '500' }}>{lang === langCode.eng ? locename : loclname}</Typography>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ flexGrow: '1', color: mColor.emphasis_high, fontFamily: 'bold', fontSize: '0.9rem', fontWeight: '600' }}>{lang === langCode.eng ? prod2 : prod}</Typography>

                            {isorganic && <Typography style={{ borderRadius: '2px', alignSelf: 'center', padding: '1px 5px', backgroundColor: mColor.organic, color: mColor.white, fontFamily: 'bold', fontSize: '0.7rem', fontWeight: '600' }}>{string[lang].organic}</Typography>}
                            {isrfree && <Typography style={{ borderRadius: '2px', alignSelf: 'center', padding: '1px 5px', marginLeft: '5px', backgroundColor: mColor.rfree, color: mColor.white, fontFamily: 'bold', fontSize: '0.7rem', fontWeight: '600' }}>{string[lang].residue_free}</Typography>}
                            {isfpo && <Typography style={{ borderRadius: '2px', alignSelf: 'center', padding: '1px 5px', marginLeft: '5px', backgroundColor: mColor.fpo, color: mColor.white, fontFamily: 'bold', fontSize: '0.7rem', fontWeight: '600' }}>{string[lang].fpo}</Typography>}

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography style={{ flexGrow: '1', color: mColor.accent, fontFamily: 'regular', fontSize: '0.9rem', fontWeight: '500' }}>{string[lang].quantity_colon + ' ' + qty + ' ' + (string[lang].uom[qtyuom] ? string[lang].uom[qtyuom] : uom)}</Typography>
                            <Typography style={{ color: mColor.accent, fontFamily: 'regular', fontSize: '0.9rem', fontWeight: '500' }}>{string[langCode.eng].rupee_symbol + ' ' + price + '/' + (string[lang].uom[priceuom] ? string[lang].uom[priceuom] : uom2)}</Typography>
                        </div>

                        <div style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px',
                            justifyContent: 'flex-end'
                        }}>
                            {/* <Button color="secondary" href={'/editPost'}></Button> */}
                            {/* <NavLink to={{ pathname: window.location.pathname, search: window.location.search, state: { showDeleteDialog: true } }} style={{ textDecoration: 'none' }}>
                                <Typography style={{ color: mColor.emphasis_low }}>{'Delete Post'}</Typography>
                            </NavLink> */}
                        </div>
                    </div>

                    {showDeleteDialog && <Dialog
                        open={showDeleteDialog}
                        // onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >

                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">hi</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button style={{ color: mColor.emphasis_medium }} autoFocus>Agree</Button>
                        </DialogActions>
                    </Dialog>}
                </Paper >
            </NavLink>
        );
    }

}