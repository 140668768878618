import InfiniteScroll from 'react-infinite-scroller'
import { Log } from '../utils';

export class InfiniteScrollExtend extends InfiniteScroll {

    componentDidUpdate(prevProps, prevState) {
        Log.verbose('pageNo', 'extend >> ' + prevProps.pageStart + ' >> ' + this.props.pageStart)
        if (prevProps.pageStart !== this.props.pageStart) {
            this.pageLoaded = 0;
        }
        this.attachScrollListener();
    }
}