import React from 'react'
import { Log } from '../utils';

export class ImageWithFallbacks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            src: props.src,
            fallbackIndex: 0,
            fallbacks: this.props.fallbacks ? this.props.fallbacks : [],
        }
    }
    onError() {

        Log.verbose(this.props.id + "errored", this.props.id + ' >> ' + this.state.fallbackIndex + ' >> ' + this.props.fallbacks.length + ' >> ' + (this.state.fallbackIndex >= this.props.fallbacks.length));
        if (this.state.fallbackIndex >= this.state.fallbacks.length) {
            return;
        }
        Log.verbose(this.props.id + "errored after", this.props.id + ' >> ' + this.state.fallbackIndex + ' >> ' + this.props.fallbacks.length + ' >> ' + (this.state.fallbackIndex >= this.props.fallbacks.length));
        this.setState((prevState, props) => ({
            src: prevState.fallbacks[prevState.fallbackIndex],
            fallbackIndex: prevState.fallbackIndex + 1
        }))
    }
    render() {
        Log.verbose(this.props.id + "errored render", this.props.id + ' >> ' + this.state.fallbackIndex + ' >> ' + this.props.fallbacks.length + ' >> ' + this.state.src);
        if (this.props.style) {
            return <img style={this.props.style} src={this.state.src} onError={() => this.onError()} />;
        }
        else {
            return <img src={this.state.src} onError={() => this.onError()} />;

        }
    }
}