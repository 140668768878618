import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { dataSource, pathConst, dlConst, apiConst } from '../constants'
import { makeStyles, Typography, GridList, GridListTile, Paper } from '@material-ui/core';
import { FontIcon } from '../components'
import { color as mColor } from '../res'
import '../App.css'
import { UserContext } from '../context';
import { apiUrl, apiInterface } from '../network';

const useStyles = makeStyles(theme => ({

    title: {
        marginTop: 10,
        marginRight: 20,
        marginBottom: 20,
        marginLeft: 20,
    },
    itemTitle: { fontSize: '1rem', fontFamily: 'regular', color: mColor.emphasis_high, paddingBottom: 10 },
    count: {
        fontSize: '0.7rem',
        fontFamily: 'regular',
        color: mColor.accent,
        paddingTop: '5px',
        paddingRight: '5px',
        zIndex: '1',
        position: 'absolute',
        right: '0'
    }

}));

export default function PostType(props) {

    const classes = useStyles()
    const userContext = useContext(UserContext)
    const postOptions = dataSource.search_add_post(props.st, userContext.lang)

    const title = dataSource.search_add_post_title(props.st, userContext.lang)


    if (!userContext.postCount) {
        const payLoad = {

        }
        apiInterface.post(apiUrl.postCount, payLoad).then((res) => {

            // {"status":{"value":"1","returncode":"1"},"data":[
            // {"count":"4377","type":"bp"},{"count":"9862","type":"sp"},{"count":"544","type":"norp"},
            // {"count":"802","type":"frp"},{"count":"11081","type":"biz"},{"count":"734","type":"news"},
            // {"count":"9056","type":"qa"},{"count":"574","type":"story"},{"count":"30","type":"job"},
            // {"count":"14","type":"schm"},{"count":"22","type":"event"}]}

            const counts = res.data.data

            const postCount = {}

            for (const count in counts) {

                const data = counts[count]

                if (data.type === 'bp') {
                    postCount.bp = parseInt(data.count)
                } else if (data.type === 'sp') {
                    postCount.sp = parseInt(data.count)
                } else if (data.type === 'norp') {
                    postCount.norp = parseInt(data.count)
                } else if (data.type === 'frp') {
                    postCount.frp = parseInt(data.count)
                } else if (data.type === 'biz') {
                    postCount.biz = parseInt(data.count)
                } else if (data.type === 'news') {
                    postCount.news = parseInt(data.count)
                } else if (data.type === 'qa') {
                    postCount.qa = parseInt(data.count)
                } else if (data.type === 'story') {
                    postCount.story = parseInt(data.count)
                } else if (data.type === 'job') {
                    postCount.job = parseInt(data.count)
                } else if (data.type === 'schm') {
                    postCount.schm = parseInt(data.count)
                } else if (data.type === 'event') {
                    postCount.event = parseInt(data.count)
                }
            }

            userContext.updatePostCount(postCount)

        }).catch((err) => {

        })
    }

    return (
        <React.Fragment>
            <Typography align="center" className={classes.title} >{title}</Typography>

            <GridList cols={2} spacing={'10'}>
                {postOptions.map(pt => (
                    <GridListTile key={pt.pt} >
                        <NavLink to={pathConst.prodListing(props.st, pt.pt)} style={{ textDecoration: 'none' }}>
                            <Paper elevation={2}>

                                <Typography align="right" className={classes.count}>

                                    {props.st === dlConst.ST_SEARCH_POST && userContext.postCount &&
                                        (pt.pt === apiConst.ptBuy ? userContext.postCount.bp :
                                            (pt.pt === apiConst.ptSell ? userContext.postCount.sp :
                                                (pt.pt === apiConst.ptGetOnRent ? userContext.postCount.norp :
                                                    (pt.pt === apiConst.ptGiveOnRent ? userContext.postCount.frp : ''))))}

                                </Typography>
                                <FontIcon style={{ color: pt.color, fontSize: '4rem', padding: '10px' }} fontIcon={pt.icon} />
                                <Typography align="center" className={classes.itemTitle}>{pt.title}</Typography>
                            </Paper >
                        </NavLink>
                    </GridListTile>
                ))}
            </GridList>
        </React.Fragment>

    );
}