export const dlConst = {

    ST_HOME: 'home',
    ST_SEARCH_POST: 'searchPost',
    ST_ADD_POST: 'addPost',
    ST_SEARCH_LISTING: 'searchListing',
    ST_PRODUCT_DETAIL: 'PD',
    ST_MY_POST: 'myPost',
    ST_SHARE_THIS_APP: 'shareThisApp',

    st: 'st',
    type: 'type',
    id: 'id',
    pid: 'pid',
    pname: 'pname',
    mobno: 'mobno',
    title: 'title',
    url: 'url',

    auth: 'auth',

    ref: 'ref',

    amhi_kastkar: 'amhi_kastkar',

    form: 'form',



}