import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, ListItem, List, ListItemText, Drawer } from '@material-ui/core';
import { pathConst, dataSource, dlConst, langCode } from '../constants';
import { NavLink, Redirect } from 'react-router-dom'
import { color, fontIcon, string } from '../res';
import { FontIcon } from './FontIcon';
import { Log } from '../utils';
import { UserContext } from '../context';

export function ToolBar(props, route, location, history) {

    const userContext = useContext(UserContext)

    const [state, setState] = React.useState({
        openDrawer: false,
        loadMyPost: false,
    });

    const toggleDrawer = (open) => event => {

        Log.verbose('ttt', 'toggleDrawer >> ' + open)

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        // if (!open) {
        //     setState({ ...state, openDrawer: open, loadMyPost: false });
        // }
        // else {
        setState({ ...state, openDrawer: open });
        // }
    };

    const menuListArray = dataSource.menuList(userContext.lang)

    const handleListItemClick = (st) => {
        Log.verbose('aaa', 'handleListItemClick ' + st)

        // if (st === dlConst.ST_SHARE_THIS_APP) {

        //     if (window.AppInterface) {
        //         const webCallbackPayload = { type: 'share', message: 'https://play.google.com/store/apps/details?id=com.globalfarm.marketyard' }
        //         window.AppInterface.webCallback(JSON.stringify(webCallbackPayload))
        //     }
        //     else {

        //     }


        //     // if (navigator.share) {
        //     //     navigator.share({
        //     //         title: 'Web Fundamentals',
        //     //         text: 'Check out Web Fundamentals — it rocks!',
        //     //         url: 'https://developers.google.com/web',
        //     //     })
        //     //         .then(() => console.log('Successful share'))
        //     //         .catch((error) => console.log('Error sharing', error));
        //     // }

        //     window.open(st, '_self')
        // }
        // else {
        //     // <Redirect to={st} />

        //     window.open(st, '_parent')
        // }

        // if (st === '/' + dlConst.ST_MY_POST) {
        //     // props.history.push(props.location.pathname + props.location.search, { ...props.location.state, showApplyFilter: true });
        //     // props.history.push(st);
        //     console.log("aaa", "in my post")
        //     setState({ ...state, loadMyPost: true });
        // } else {
        //     window.open(st, '_parent')
        // }

        window.open(st, '_parent')

        // if (navigator.share) {
        //     navigator.share({
        //         title: 'Web Fundamentals',
        //         text: 'Check out Web Fundamentals — it rocks!',
        //         url: 'https://developers.google.com/web',
        //     })
        //         .then(() => {
        //             console.log('Successful share')
        //             alert('share successful')
        //         })
        //         .catch((error) => {
        //             console.log('Error sharing', error)
        //             alert('share error')
        //         });
        // }
        // else {
        //     alert('no share navigator')
        // }
    }

    const menuList = () => {

        Log.verbose("aaa", "menu list")
        Log.verbose(state)

        return <div
            style={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {menuListArray.map((menu, index) => (

                    <ListItem onClick={() => { handleListItemClick(menu.st) }} button key={menu.title}>

                        <FontIcon style={{ color: color.accent, fontSize: '1rem', marginRight: '10px', alignSelf: 'center' }} fontIcon={menu.icon} />
                        <ListItemText primary={menu.title} />

                    </ListItem>
                ))}
            </List>
        </div>
    }

    return (
        <AppBar position="sticky" >
            <Toolbar style={{ padding: 0 }}>
                <FontIcon onClick={toggleDrawer(true)} style={{ color: color.white, fontSize: '1.2rem', alignSelf: 'center', padding: '15px 20px' }} fontIcon={fontIcon.icon_hamburger} />
                <NavLink to={pathConst.home} style={{ textDecoration: 'none', flexGrow: 1 }}>
                    <Typography style={{ color: color.white }} variant="h6">{string[langCode.eng].marketyard}</Typography>
                </NavLink>
                <FontIcon onClick={() => handleListItemClick(pathConst.contactUs)} style={{ color: color.white, fontSize: '1.2rem', alignSelf: 'right', padding: '15px 20px' }} fontIcon={fontIcon.icon_phone_2} />
            </Toolbar>
            <Drawer open={state.openDrawer} onClose={toggleDrawer(false)}>
                {menuList()}
            </Drawer>

            {state.loadMyPost && <Redirect to={pathConst.myPost} />}
        </AppBar>
    );
}