import React from 'react'
import { stateCode, langCode } from '../constants'
import { Log } from '../utils';
// import {} from ''


export const UserContext = React.createContext({})

export class UserStore extends React.Component {
    state = {

        uid: '-1',
        pinnumber: '-1',
        lid: '250000000',
        lename: 'Maharashtra',
        llname: 'महाराष्ट्र',
        lang: langCode.mar,

        isLoggedIn: false,
        user: null,
        // user: { "validity": "1", "uid": "1564826500101", "username": "Chetan", "lastname": "Patil", "mobilenumber": "9975300970", "birthdate": "null", "rating": "5.0", "ratedby": "1", "lid": "250204001", "lename": "Maharashtra | Ahmednagar | Karjat", "llname": "महाराष्ट्र | अहमदनगर | कर्जत", "isbiz": "false", "biztype": "0", "bizname": "null", "userpin": "54531993", "pincode": "-1", "category": "0" },
        // user: { "validity": "365", "uid": "1477077272110", "username": "Marketyard Agrisolution", "lastname": "Team", "mobilenumber": "9975300970", "birthdate": "1989-01-20 05:30:00.0", "rating": "4.0", "ratedby": "6", "lid": "251404001", "lename": "Jalgaon | Bhusawal | Bhusawal City", "llname": "जळगाव | भुसावळ | भुसावळ शहर", "isbiz": "false", "biztype": "0", "bizname": "", "userpin": "41453179" },
        // user: { "status": { "value": "1", "returncode": "1" }, "data": [{ "validity": "365", "uid": "1477077272110", "username": "Marketyard Agrisolution", "lastname": "Team", "mobilenumber": "9975300970", "birthdate": "1989-01-20 05:30:00.0", "rating": "4.0", "ratedby": "6", "lid": "251404001", "lename": "Jalgaon | Bhusawal | Bhusawal City", "llname": "जळगाव | भुसावळ | भुसावळ शहर", "isbiz": "false", "biztype": "0", "bizname": "", "userpin": "41453179" }] },
        // user: {"status":{"value":"1","returncode":"1"},"data":[{"validity":"365","uid":"1477077272110","username":"मार्केटयार्ड अॅग्रीसोल्युशन्स","lastname":"टीम","mobilenumber":"9975300970","birthdate":"1989-01-20 05:30:00.0","rating":"4.0","ratedby":"6","lid":"251404001","lename":"Maharashtra | Jalgaon | Bhusawal","llname":"महाराष्ट्र | जळगाव | भुसावळ","isbiz":"false","biztype":"0","bizname":"","userpin":"43282452"}]},

        postCount: null
    }

    componentDidMount() {
        // this.updateUser(this.state.user)
    }

    updateUser = (user) => {

        Log.verbose('update user', user)

        if (user) {

            this.setState({
                isLoggedIn: true,
                uid: user.uid,
                pinnumber: user.userpin,
                lid: user.lid,
                lename: user.lename,
                llname: user.llname,
                // lang: '',
                user: user,

            })
        }
        else {

            this.setState({
                isLoggedIn: false,
                uid: '-1',
                pinnumber: '-1',
                lid: '',
                lename: '',
                llname: '',
                // lang: '',
                user: null,

            })
        }
    }

    updatePostCount = (postCount) => {
        this.setState({
            postCount: postCount
        })
    }

    updateLang = (lang) => {

        if (lang === 'mh') {
            lang = langCode.mar
        } else {
            lang = langCode.eng
        }

        this.setState({
            lang: lang
        })
    }

    render() {
        const { isLoggedIn, user, uid, pinnumber, lid, lename, llname, lang, postCount } = this.state
        return (
            <UserContext.Provider value={{

                uid: uid,
                pinnumber: pinnumber,

                lid: lid,
                lename: lename,
                llname: llname,
                lang: lang,

                isLoggedIn: isLoggedIn,

                user: user,
                updateUser: this.updateUser,

                postCount: postCount,
                updatePostCount: this.updatePostCount,
                updateLang: this.updateLang,

            }}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

// Common Api Handeling with errors
// Dropdown design same
// locale and strings
// default screen for page not found
// check when image is uploaded while adding post
// Need to login prompt for unauthorised access
// Banner display
// Analytics
// Notification
// My post
// Delete Post
// Edit Post
// PWA for Android and iOS
// 
// Profile in menu
// Profile Screen

// Add deeplink host params in manifest like www and m and secure scheme as well

// server issues
// new user through gateway
// validity null should be 365
// state mapping default is Gujarat if state does not match 
// lid by default 170100000
// lename Gujarat
// llname ગુજરાત | ગુજરાત
// same user pin is generated
// for promo code mktydbagic only gateway service should work
// location pincode in user refresh response

// Android User Agent
// Mozilla/5.0 (Linux; Android 7.0; Redmi Note 4 Build/NRD90M; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/64.0.3282.137 Mobile Safari/537.36
// Mobile Chrome User Agent
// Mozilla/5.0 (Linux; Android 5.0; SM-G900P Build/LRX21T) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.142 Mobile Safari/537.36
// Desktop Chrome User Agent
// Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.142 Safari/537.36
// Iphone Mobile chrome
// Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1



